import { Switch } from "@headlessui/react";
import { Add, Remove } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { Control, Controller, UseFormRegister } from "react-hook-form";
import { extractFilenameFromUrl } from "../../utils/FormatFunctions";
import Button from "../general/Button";
import {
  CloudArrowUpIcon,
  DocumentTextIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useDropzone } from "react-dropzone";

interface Option {
  label: string;
  value: string;
}

interface FileWithPreview extends File {
  preview?: string;
}

// Add mapping for file type display names
const FILE_TYPE_DISPLAY_NAMES: Record<string, string> = {
  "image/jpeg": "JPG",
  "image/jpg": "JPG",
  "image/png": "PNG",
  "application/pdf": "PDF",
  "application/msword": "DOC",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    "DOCX",
  "application/vnd.ms-excel": "XLS",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "XLSX",
  "text/plain": "TXT",
  "text/csv": "CSV",
};

export type InputType =
  | "text"
  | "number"
  | "email"
  | "password"
  | "date"
  | "textarea"
  | "select"
  | "radio"
  | "radio-na"
  | "checkbox"
  | "switch"
  | "file";

interface InputProps {
  name: string;
  type: InputType;
  label: string;
  options?: string[] | Option[];
  register?: UseFormRegister<any>;
  control?: Control<any>;
  errors?: any;
  placeholder?: string;
  showLabel?: boolean;
  disabled?: boolean;
  onBlur?: () => void;
  fileProps?: {
    files: FileWithPreview[];
    setFiles: React.Dispatch<React.SetStateAction<FileWithPreview[]>>;
    handleViewFile: (file: string) => void;
    allowedFileTypes?: Record<string, string[]>;
    maxFileSize?: number | undefined; // in bytes
    maxFiles?: number | undefined;
  };
}

const Input: React.FC<InputProps> = ({
  name,
  type,
  label,
  options = [],
  register,
  errors,
  placeholder = "",
  showLabel = true,
  control,
  disabled = false,
  fileProps: {
    files = [],
    setFiles = () => {},
    handleViewFile = () => {},
    allowedFileTypes = {
      "image/jpeg": [],
      "image/png": [],
      "image/jpg": [],
    },
    maxFileSize,
    maxFiles,
  } = {},
}) => {
  const [fileError, setFileError] = useState<string>("");

  let inputElement;
  switch (type) {
    case "textarea":
      inputElement = register ? (
        <textarea
          placeholder={placeholder}
          className={`block w-full focus:ring-2 focus:ring-inset focus:ring-accent-500 rounded border-0 bg-transparent py-1.5 shadow-sm ring-1 ring-inset sm:text-sm sm:leading-6 ${
            errors
              ? "ring-reds-600 ring-2 text-reds-600"
              : "ring-secondary-800 text-secondary-100"
          }`}
          {...register(name)}
        />
      ) : null;
      break;
    case "select":
      inputElement = register ? (
        <select
          className={`block w-full rounded border-0 bg-transparent py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-accent-500 sm:text-sm sm:leading-6 ${
            errors
              ? "ring-reds-600 ring-2 text-reds-600"
              : "ring-secondary-800 text-secondary-100"
          }`}
          {...register(name)}
        >
          <option value="" disabled>
            Select an option
          </option>
          {options.map((option, index) =>
            typeof option === "string" ? (
              <option className="text-secondary" key={index} value={option}>
                {option}
              </option>
            ) : (
              <option
                className="text-secondary"
                key={index}
                value={option.value}
              >
                {option.label}
              </option>
            )
          )}
        </select>
      ) : null;
      break;
    case "radio":
    case "radio-na":
      inputElement = register ? (
        <div className="flex w-full justify-end gap-4 ">
          {options.map((option, index) =>
            typeof option === "string" ? (
              <label key={index} className="inline-flex items-center">
                <input
                  type="radio"
                  value={option}
                  className="form-radio text-accent-500 focus:ring-secondary-100 bg-transparent"
                  {...register(name)}
                />
                <span className="ml-2">{option}</span>
              </label>
            ) : (
              <label key={index} className="inline-flex items-center">
                <input
                  type="radio"
                  value={option.value}
                  className="form-radio text-accent-500 focus:ring-secondary-100 bg-transparent"
                  {...register(name)}
                />
                <span className="ml-2">{option.label}</span>
              </label>
            )
          )}
        </div>
      ) : null;
      break;
    case "checkbox":
      inputElement = register ? (
        <div className="flex  flex-col w-full space-y-2">
          {options.map((option, index) =>
            typeof option === "string" ? (
              <label key={index} className="inline-flex items-center">
                <input
                  type="checkbox"
                  className="form-checkbox rounded text-secondary-100 focus:ring-secondary-100 bg-transparent"
                  {...register(name)}
                />
                <span className="ml-2">{option}</span>
              </label>
            ) : (
              <label key={index} className="inline-flex items-center">
                <input
                  type="checkbox"
                  className="form-checkbox rounded text-secondary-100 focus:ring-secondary-100 bg-transparent"
                  {...register(name)}
                />
                <span className="ml-2">{option.label}</span>
              </label>
            )
          )}
        </div>
      ) : null;
      break;
    case "switch":
      inputElement = control ? (
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Switch
              checked={value}
              onChange={onChange}
              className={`group flex w-8 flex-none cursor-pointer ui-checked:bg-accent-500 ui-not-checked:bg-secondary-1000 rounded-full p-px ring-1 ring-inset ring-secondary-100/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-500`}
            >
              <span className="sr-only">{label}</span>
              <span
                aria-hidden="true"
                className="h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-secondary-100/5 transition duration-200 ease-in-out ui-checked:translate-x-3.5"
              />
            </Switch>
          )}
        />
      ) : null;
      break;
    case "number":
      inputElement = control ? (
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            const currentValue = Number(field.value || 0);
            return (
              <div className="flex w-full justify-between items-center rounded border border-secondary-800">
                <button
                  type="button"
                  onClick={() => {
                    const newValue = currentValue - 1;
                    field.onChange(newValue);
                  }}
                  className="px-2"
                >
                  <Remove fontSize="small" />
                </button>
                <input
                  type="number"
                  {...field}
                  onChange={(e) => {
                    const value = e.target.value;
                    field.onChange(value === "" ? "" : Number(value));
                  }}
                  className="max-w-24 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none p-2 border-none focus:ring-0 text-center"
                  value={field.value ?? ""}
                />
                <button
                  type="button"
                  onClick={() => {
                    const newValue = currentValue + 1;
                    field.onChange(newValue);
                  }}
                  className="px-2"
                >
                  <Add fontSize="small" />
                </button>
              </div>
            );
          }}
        />
      ) : null;
      break;
    case "file":
      inputElement = control ? (
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            const filesFields = Array.isArray(field.value)
              ? field.value
              : field.value
              ? [field.value]
              : [];

            const onDrop = (acceptedFiles: File[], rejectedFiles: any[]) => {
              if (rejectedFiles.length > 0) {
                const errorMessages = rejectedFiles.map((rejection) => {
                  if (
                    rejection.errors[0].code === "file-too-large" &&
                    maxFileSize
                  ) {
                    return `File ${
                      rejection.file.name
                    } is too large. Max size is ${
                      maxFileSize / (1024 * 1024)
                    }MB`;
                  }
                  if (rejection.errors[0].code === "file-invalid-type") {
                    return `File ${
                      rejection.file.name
                    } has invalid type. Allowed types: ${Object.keys(
                      allowedFileTypes
                    )
                      .map((type) => FILE_TYPE_DISPLAY_NAMES[type] || type)
                      .join(", ")}`;
                  }
                  return rejection.errors[0].message;
                });
                setFileError(errorMessages.join(". "));
                return;
              }

              if (maxFiles && files.length + acceptedFiles.length > maxFiles) {
                setFileError(`Maximum ${maxFiles} files allowed`);
                return;
              }

              setFileError("");
              const newFiles = acceptedFiles.map((file) =>
                Object.assign(file, {
                  preview: URL.createObjectURL(file),
                })
              );

              setFiles((prevFiles) => [...prevFiles, ...newFiles]);

              // Update form value with just the filenames
              const currentFiles = field.value || [];
              const newFileValue = [...currentFiles, ...newFiles.map(file => file.name)];
              field.onChange(newFileValue);
            };

            const { getRootProps, getInputProps, isDragReject } = useDropzone({
              accept: allowedFileTypes,
              maxSize: maxFileSize,
              maxFiles: maxFiles,
              onDrop,
            });

            return (
              <>
                <div>
                  <div
                    {...getRootProps()}
                    className={`flex flex-col items-center justify-center rounded-sm border-secondary-900 border-dashed border sm:p-6 p-4 cursor-pointer text-secondary-600 hover:text-gray-600 ${
                      isDragReject ? "border-reds-500" : ""
                    }`}
                  >
                    <CloudArrowUpIcon className="w-8 h-8 text-secondary-500" />
                    <input {...getInputProps()} />
                    <p className="text-sm">
                      Drag 'n' drop here, or{" "}
                      <span className="text-accent-500">select files</span>
                    </p>
                    <p className="text-xs text-secondary-500 mt-1">
                      {Object.keys(allowedFileTypes)
                        .map((type) => FILE_TYPE_DISPLAY_NAMES[type] || type)
                        .join(", ")}{" "}
                      -{" "}
                      {maxFileSize && (
                        <span>Max size: {maxFileSize / (1024 * 1024)}MB</span>
                      )}
                      {maxFileSize && maxFiles && <span>, </span>}
                      {maxFiles && <span>Max files: {maxFiles}</span>}
                    </p>
                    <div className="text-xs text-secondary-500"></div>
                    {fileError && (
                      <p className="text-xs text-reds-500 mt-1">{fileError}</p>
                    )}
                  </div>
                  <ul className="flex flex-col w-full mt-4">
                    {filesFields.length > 0 && (
                      <h3 className="text-secondary-100 pb-2">
                        Uploaded Files
                      </h3>
                    )}

                    {filesFields.map((file, index) => (
                      <li
                        key={typeof file === 'string' ? file : file.path}
                        className="flex justify-between py-1.5 px-2 border-secondary-1000 rouded border rounded-sm mr-2 mb-2"
                      >
                        <span
                          onClick={() => handleViewFile(file)}
                          className="text-secondary-100 truncate flex items-center gap-x-2 cursor-pointer"
                        >
                          <DocumentTextIcon className="w-4 h-4" />{" "}
                          {typeof file === 'string' 
                            ? extractFilenameFromUrl(file)
                            : file.path}
                        </span>
                        <div className="flex items-center gap-x-2">
                          {typeof file !== 'string' && file.size && (
                            <span className="text-secondary-100 text-xs">
                              {(file.size / (1024 * 1024)).toFixed(2)}MB
                            </span>
                          )}
                          <button
                            className="text-secondary-500 rounded-full bg-secondary-1100 bg-opacity-35"
                            type="button"
                            onClick={() => {
                              const newFiles = filesFields.filter(
                                (_, i) => i !== index
                              );
                              field.onChange(newFiles);
                              if (typeof file !== 'string') {
                                setFiles(files.filter((f) => f.name !== file));
                              }
                            }}
                          >
                            <TrashIcon className="w-4 h-4" />
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <input
                  type="file"
                  onChange={(e) => {
                    if (e.target.files && e.target.files.length > 0) {
                      const newFile = e.target.files[0];
                      if (maxFiles && files.length >= maxFiles) {
                        setFileError(`Maximum ${maxFiles} files allowed`);
                        e.target.value = "";
                        return;
                      }

                      if (maxFileSize && newFile.size > maxFileSize) {
                        setFileError(
                          `File is too large. Max size is ${
                            maxFileSize / (1024 * 1024)
                          }MB`
                        );
                        e.target.value = "";
                        return;
                      }

                      if (
                        !Object.keys(allowedFileTypes).includes(newFile.type)
                      ) {
                        setFileError(
                          `Invalid file type. Allowed types: ${Object.keys(
                            allowedFileTypes
                          )
                            .map(
                              (type) => FILE_TYPE_DISPLAY_NAMES[type] || type
                            )
                            .join(", ")}`
                        );
                        e.target.value = "";
                        return;
                      }

                      const fileWithPreview = Object.assign(newFile, {
                        preview: URL.createObjectURL(newFile),
                      });

                      setFiles((prevFiles) => {
                        const updatedFiles = [...prevFiles, fileWithPreview];
                        // Update the form value with just the filename
                        const currentFiles = field.value || [];
                        field.onChange([...currentFiles, newFile.name]);
                        return updatedFiles;
                      });
                    }
                    e.target.value = "";
                  }}
                  className="hidden"
                  id={name}
                />
              </>
            );
          }}
        />
      ) : null;
      break;
    default:
      inputElement = register ? (
        <input
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          className={`block w-full rounded border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-accent-500 sm:text-sm sm:leading-6 ${
            errors
              ? "ring-reds-600 ring-2 text-reds-600"
              : "text-secondary-100 ring-secondary-800"
          } ${
            disabled
              ? "bg-secondary-1100 text-secondary-500 cursor-not-allowed"
              : "bg-transparent "
          }`}
          {...register(name)}
        />
      ) : null;
      break;
  }

  return (
    <div className="bg-primary w-full">
      {showLabel && (
        <label
          htmlFor={name}
          className="block text-sm font-medium leading-6 mb-2"
        >
          {label}
        </label>
      )}
      <div className="bg-transparent">{inputElement}</div>
      {errors && (
        <span className="text-reds-600 text-sm">{errors.message}</span>
      )}
    </div>
  );
};

export default Input;
{
  /* <Controller
            name={`${name}`}
            control={control}
            render={({ field }) => (
              <div className="flex w-1/2 justify-end gap-2">
                <label
                  className={classNames(
                    "flex max-w-36 flex-1 items-center gap-4 rounded py-1.5 px-3",
                    {
                      "bg-accent-1100 border-accent-500 border text-secondary-100":
                        field.value === "Pass",
                    }
                  )}
                >
                  <input
                    type="radio"
                    value="Pass"
                    className={classNames({
                      "text-accent-300 h-4 w-4 border-secondary-1000  focus:ring-accent-300":
                        field.value === "Pass",
                    })}
                    checked={field.value === "Pass"}
                    onChange={() => field.onChange("Pass")}
                  />
                  <span>Pass</span>
                </label>
                <label
                  className={classNames(
                    "flex max-w-36 flex-1 items-center gap-4 rounded py-1.5 px-3",
                    {
                      "bg-reds-1100 border-reds-500 border text-secondary-100":
                        field.value === "Fail",
                    }
                  )}
                >
                  <input
                    type="radio"
                    value="Fail"
                    className={classNames({
                      "text-reds-300 h-4 w-4 border-secondary-1000  focus:ring-reds-300":
                        field.value === "Fail",
                    })}
                    checked={field.value === "Fail"}
                    onChange={() => field.onChange("Fail")}
                  />
                  <span>Fail</span>
                </label>
                {dataType === "radio-na" && (
                  <label
                    className={classNames(
                      "flex max-w-36 flex-1 items-center gap-4 rounded py-1.5 px-3",
                      {
                        "bg-secondary-1100 border-secondary-500 border text-secondary-100":
                          field.value === "N/A",
                      }
                    )}
                  >
                    <input
                      type="radio"
                      value="N/A"
                      className={classNames({
                        "text-secondary-300 h-4 w-4 border-secondary-1000  focus:ring-secondary-300":
                          field.value === "N/A",
                      })}
                      checked={field.value === "N/A"}
                      onChange={() => field.onChange("N/A")}
                    />
                    <span>N/A</span>
                  </label>
                )}
              </div>
            )}
          /> */
}
