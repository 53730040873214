import * as Yup from "yup";

export const newInputSchema = Yup.object().shape({
  name: Yup.string().required("Input name is required."),
  dataType: Yup.string().required("Please select an option."),
  validator: Yup.object().shape({
    lowerLimit: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable(),
    upperLimit: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable()
      .when("lowerLimit", (lowerLimit, schema) => {
        return lowerLimit
          ? schema.min(
              lowerLimit as unknown as number,
              "Upper limit must be greater than or equal to lower limit"
            )
          : schema;
      }),
    required: Yup.boolean(),
    requireNote: Yup.boolean(),
  }),
});

export const validationSchema = Yup.object().shape({
  taskType: Yup.string().required("Task type is required"),
  category: Yup.string().required("Category is required"),
  title: Yup.string().required("Title is required"),
  startDate: Yup.string().required("Start date is required"),
  endDate: Yup.string(),
  recurrence: Yup.object({
    frequency: Yup.string().required("Please select a frequency"),
  }).required(),
  source: Yup.string().required("Please select a source"),
  details: Yup.string()
    .required("Task details are required")
    .min(10, "Details must be at least 10 characters"),
  inputs: Yup.array()
    .of(
      Yup.object().shape({
        isDeleted: Yup.boolean(),
        name: Yup.string().required(),
        dataType: Yup.string().required(),
        validator: Yup.object().shape({
          lowerLimit: Yup.number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable(),
          upperLimit: Yup.number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable()
            .when("lowerLimit", (lowerLimit, schema) => {
              return lowerLimit
                ? schema.min(
                    lowerLimit as unknown as number,
                    "Upper limit must be greater than or equal to lower limit"
                  )
                : schema;
            }),
          required: Yup.boolean(),
          requireNote: Yup.boolean(),
        }),
      })
    )
    .min(1, "At least one input field is required")
    .required(),
  notifyVendor: Yup.boolean().required(),
  skipWeekends: Yup.boolean().required(),
  sourceTemplateId: Yup.string().default(""),
  vendor: Yup.string(),
  map: Yup.string(),
  attachments: Yup.array().of(Yup.string().required()).required(),
  facility: Yup.string().required(),
  skippable: Yup.boolean(),
}); 