import React, { useState, useEffect, useContext } from "react";
import TeamList from "../../updatedcomponents/tables/TeamList";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import NewUserForm from "../../updatedcomponents/forms/NewUserForm";
import { Add, ArrowBack } from "@mui/icons-material";
import Pagination from "../../updatedcomponents/table-components/Pagination";
import useFetchData from "../../hooks/useFetchData";
import { useFacility } from "../../hooks/useFacility";
import { useAuth } from "../../hooks/useAuth";
import Button from "../../updatedcomponents/general/Button";
import { useTeam } from "../../hooks/useTeam";
import Modal from "../../updatedcomponents/general-ui/Modal";
import SearchInput from "../../updatedcomponents/general-ui/SearchBar";

const Team = () => {
  const { currentUser } = useAuth();
  const {
    handleAddUser,
    modalShowState,
    updateModalShowState,
    searchTerm,
    handleSearch,
  } = useTeam();

  const permissions = currentUser.access;

  return (
    <div className="flex flex-col h-full bg-primary">
      <PageHeader
        title={"Team"}
        mobileTitle={
          <>
            <span className="inline-flex gap-2">Team</span>
          </>
        }
        subtitle={"All users in the current facility"}
        buttons={
          <div className="flex flex-col sm:flex-row w-full h-fit justify-evenly sm:gap-4 gap-2 bg-primary">
            <SearchInput
              searchTerm={searchTerm}
              handleSearchChange={(searchTerm) => handleSearch(searchTerm)}
            />
            {(permissions === "regional" ||
              permissions === "organization-admin") && (
              <Button
                icon="Add"
                children="Add User"
                onClick={handleAddUser}
                styleString="primary"
              />
            )}
          </div>
        }
      />
      <TeamList  />

      <Modal
        open={modalShowState.showNewUser}
        setOpen={() => updateModalShowState("showNewUser", false)}
        title="New Work Order"
        subtitle="Get started by filling in the information below to add a new user to your facility."
        content={<NewUserForm />}
      />
    </div>
  );
};

export default Team;
