import { Control } from "react-hook-form";
import { LabelOutlined } from "@mui/icons-material";
import Combobox from "../general-ui/Combobox";
import { capitalizeString } from "../../utils/FormatFunctions";
import { NewRecurringTaskFormData } from "./RecurringTaskForm";

interface TemplateSelectionProps {
  control: Control<NewRecurringTaskFormData>;
  templates: any[];
  tasks: any[];
  selectedTemplate: any;
}

const TemplateSelection = ({
  control,
  templates,
  tasks,
  selectedTemplate,
}: TemplateSelectionProps) => {
  return (
    <div className="pl-4 pt-4">
      <div className="flex flex-col">
        <label
          htmlFor="project-name"
          className="block text-sm font-medium leading-6 text-gray-800 sm:mt-1.5"
        >
          Select a template
        </label>
        <div className="w-full">
          <Combobox
            name="sourceTemplateId"
            control={control}
            placeholder="Search and select a template"
            showLabel={false}
            options={[
              { value: "", label: "Build from new" },
              ...templates.map((template) => ({
                value: template._id,
                label: capitalizeString(template.title),
              })),
            ]}
            isOptionDisabled={(option) =>
              tasks.some((task) => task.sourceTemplateId === option.value)
            }
          />
        </div>
      </div>
      <div className="sm:col-span-3 min-h-56">
        {selectedTemplate && (
          <>
            <h2 className="text-secondary-100 text-lg pt-4">Template Overview</h2>
            <div className="overflow-y-auto scrollbar-thin border border-secondary-1000 rounded my-2 max-h-96 p-2">
              <dl>
                {[
                  {
                    label: "Task Title",
                    value: capitalizeString(selectedTemplate.title),
                  },
                  {
                    label: "Task Type",
                    value: capitalizeString(selectedTemplate.taskType),
                  },
                  {
                    label: "Task Category",
                    value: capitalizeString(selectedTemplate.category),
                  },
                  {
                    label: "Frequency",
                    value: capitalizeString(
                      selectedTemplate.recurrence.frequency
                    ),
                  },
                  {
                    label: "Inputs",
                    value: selectedTemplate.inputs.map((input: any, index: number) => (
                      <li
                        key={`${input}-${index}`}
                        className="flex flex-col text-xs"
                      >
                        <div className="flex items-center gap-2">
                          <LabelOutlined
                            className="text-secondary-100"
                            fontSize="small"
                          />
                          {capitalizeString(decodeURIComponent(input.name))}
                          <span className="bg-secondary-1100 py-1 px-2 w-fit text-xs rounded-full">
                            {capitalizeString(input.dataType)}
                          </span>
                        </div>
                      </li>
                    )),
                  },
                  {
                    label: "Description",
                    value: capitalizeString(selectedTemplate.details),
                  },
                  {
                    label: "Regulations",
                    value: selectedTemplate?.regulations?.join(", "),
                  },
                ].map((item, index) => (
                  <div
                    key={index}
                    className="py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
                  >
                    <dt className="text-sm font-medium leading-6 text-secondary-100">
                      {item.label}
                    </dt>
                    <dd className="mt-1 flex text-sm leading-6 text-secondary-400 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">
                        {Array.isArray(item.value) ? (
                          <ul>{item.value}</ul>
                        ) : (
                          item.value
                        )}
                      </span>
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TemplateSelection; 