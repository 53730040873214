import React, { useState, useEffect, useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { Add, LabelOutlined, Notes, Remove } from "@mui/icons-material";
import StatusBadge from "../general-ui/StatusBadges";
import {
  capitalizeString,
  decodedString,
  extractFilenameFromUrl,
  formatDate,
} from "../../utils/FormatFunctions";
import Button from "../general/Button";
import { TaskInstance } from "../../types/TaskInstance";
import Input, { InputType } from "../general-ui/Input";
import { useRecurring } from "../../hooks/useRecurring";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "../../hooks/useAuth";

interface Input {
  dataType: InputType;
  name: string;
  validator: {
    required: boolean;
    requireNote: boolean;
    lowerLimit?: string;
    upperLimit?: string;
  };
  isDeleted?: boolean;
  _id: string;
}

interface TaskInstanceFormProps {
  currentIndex: number;
  goToSection: (index: number) => void;
  goToNextSection: () => void;
  goToPreviousSection: () => void;
  isFirstSection: boolean;
  isLastSection: boolean;
  onDirtyStateChange: (isDirty: boolean) => void;
}

interface FormData {
  Notes?: string;
  [key: string]: any;
  currentSection: number;
}

const TaskInstanceForm: React.FC<TaskInstanceFormProps> = ({
  currentIndex,
  goToSection,
  goToNextSection,
  goToPreviousSection,
  isFirstSection,
  isLastSection,
  onDirtyStateChange,
}) => {
  const { currentUser } = useAuth();
  const {
    handleViewFile,
    files,
    setFiles,
    onSubmit,
    canCloseOut,
    handleCloseOut,
    instanceToEdit: logData,
  } = useRecurring();

  if (!logData) {
    return <div>Loading...</div>;
  }

  // Get active inputs
  const activeInputs = logData.parentTask.inputs.filter(
    (input) => input.isDeleted !== true
  );

  const schema = yup.object().shape({
    // Handle each input field
    ...activeInputs.reduce((acc: Record<string, any>, input) => {
      let fieldSchema: yup.Schema<any>;

      if (input.dataType === "number") {
        fieldSchema = yup
          .number()
          .transform((value, originalValue) => {
            if (originalValue === "") return undefined;
            const num = Number(originalValue);
            return isNaN(num) ? undefined : num;
          })
          .typeError("Must be a number");

        if (input.validator.required) {
          fieldSchema = fieldSchema
            .nullable()
            .required("This field is required");
        }

        // Add range validation if limits exist
        if (
          input.validator.lowerLimit !== undefined &&
          input.validator.upperLimit !== undefined
        ) {
          const min = Number(input.validator.lowerLimit);
          const max = Number(input.validator.upperLimit);

          fieldSchema = fieldSchema.test(
            "range",
            `Number must be between ${min} and ${max}`,
            (value) => {
              if (value === undefined || value === null) return true;
              return value >= min && value <= max;
            }
          );
        }
      } else if (input.dataType === "file") {
        fieldSchema = yup.mixed().transform((value) => {
          if (!value || value === "") return [];
          return Array.isArray(value) ? value : [value];
        });

        if (input.validator.required) {
          fieldSchema = fieldSchema.test(
            "required",
            "At least one file is required",
            (value) => {
              return value && (value as any[]).length > 0;
            }
          );
        }
      } else if (input.dataType === "radio" || input.dataType === "radio-na") {
        fieldSchema = yup.string().nullable();
        if (input.validator.required) {
          fieldSchema = fieldSchema.required("This field is required");
        }
      } else {
        fieldSchema = yup.string();
        if (input.validator.required) {
          fieldSchema = fieldSchema.required("This field is required");
        }
      }

      acc[input._id] = fieldSchema;
      return acc;
    }, {}),

    // Notes validation
    Notes: yup
      .string()
      .test(
        "notes-required-if-fail",
        "Notes are required when any field has a Fail status",
        function (value) {
          // Get all field values
          const allFields = this.parent;

          // Check if any radio field has "Fail" value
          const hasFailValue = Object.entries(allFields).some(
            ([key, fieldValue]) => {
              // Skip Notes field and non-string values
              if (key === "Notes" || typeof fieldValue !== "string")
                return false;
              return fieldValue === "Fail";
            }
          );

          // Notes required only if there's a fail value
          return !hasFailValue || (!!value && value.trim() !== "");
        }
      ),

    // Keep current section tracking
    currentSection: yup.number().required("Section index is required"),
  });

  const initializeData = (): Record<string, any> => {
    const taskInputs = logData.parentTask.inputs;
    const existingData: Record<string, any> =
      logData.customInput?.[currentIndex] || {};

    console.log("existingData", existingData);

    const emptyData: Record<InputType, any> = {
      text: "",
      number: "",
      radio: null,
      "radio-na": null,
      file: null,
      date: null,
      email: "",
      password: "",
      textarea: "",
      select: null,
      checkbox: false,
      switch: false,
    };

    const mapKeys = logData.parentTask.map.fields[currentIndex];
    const formInputs = taskInputs.reduce(
      (acc: Record<string, any>, field: any) => {
        acc[field._id] =
          existingData[field._id] ?? emptyData[field.dataType as InputType];
        acc["Notes"] = existingData?.["Notes"] ?? "";
        return acc;
      },
      {}
    );

    Object.assign(formInputs, mapKeys);
    formInputs["currentSection"] = currentIndex;

    return formInputs;
  };

  const {
    register,
    handleSubmit,
    formState: { isDirty, errors, isSubmitSuccessful, dirtyFields },
    reset,
    control,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: initializeData(),
  });

  useEffect(() => {
    reset(initializeData());
  }, [currentIndex, reset]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(initializeData());
    }
  }, [isSubmitSuccessful, reset]);

  // Notify parent component when isDirty changes
  useEffect(() => {
    onDirtyStateChange(isDirty);
  }, [isDirty, onDirtyStateChange, dirtyFields]);

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      className="space-y-2 h-full md:pb-0 pb-16"
    >
      <div className="flex flex-col h-full w-full">
        <div className="flex flex-col bg-secondary-1100 border p-4 gap-y-2 flex-grow md:overflow-y-auto thin-scrollbar">
          {logData.parentTask.inputs.map((input) => (
            <div
              key={input._id}
              className="flex flex-col bg-primary shadow rounded p-2 gap-y-2"
            >
              <label className="flex items-center gap-x-2">
                <LabelOutlined fontSize="small" />
                {capitalizeString(decodedString(input.name))}
                {input.validator.required ? "*" : ""}
              </label>
              <div className={`flex flex-col items-start ${input.dataType === "file" ? "w-full" : "w-fit"}`}>
                <Input
                  key={input._id}
                  register={register}
                  control={control}
                  name={input._id}
                  type={input.dataType}
                  options={
                    input.dataType === "radio-na"
                      ? ["Pass", "Fail", "N/A"]
                      : ["Pass", "Fail"]
                  }
                  label=""
                  showLabel={false}
                  errors={errors[input._id]}
                  fileProps={{
                    files,
                    setFiles,
                    handleViewFile,
                    allowedFileTypes: {
                      "image/jpeg": [".jpg", ".jpeg"],
                      "image/png": [".png"],
                      "application/pdf": [".pdf"],
                    },
                    maxFileSize: 100 * 1024 * 1024,
                    // maxFiles: 5
                  }}
                />
              </div>
            </div>
          ))}
          <div className="flex flex-col bg-primary shadow rounded p-2">
            <label className="flex items-center gap-x-2">
              <LabelOutlined fontSize="small" />
              Notes
            </label>
            <Input
              type="textarea"
              label=""
              showLabel={false}
              register={register}
              name="Notes"
              errors={errors.Notes}
            />
          </div>
        </div>
        {isDirty ? (
          <>
            {/*  <label className="flex items-center gap-2 my-4">
              <input
                type="checkbox"
                className="h-4 w-4 text-accent-500 rounded focus:ring-accent-500"
                required
                {...register("isCertified")}
              />
              <span className="text-sm">
                I hereby certify that the data recorded above is accurate to the
                best of my ability.
              </span>
            </label> */}

            <div className="fixed md:relative bottom-0 left-0 right-0 bg-primary border-t border-secondary-1000 md:border-0 flex w-full gap-6 justify-between items-center p-4">
              <Button
                styleString="secondary"
                disabled={!isDirty}
                type="button"
                children="Cancel"
                onClick={() => reset(initializeData())}
              />
              <Button
                styleString={isDirty ? "primary" : "secondary"}
                type="submit"
                children="Save Section"
                disabled={!isDirty || Object.keys(errors).length > 0}
              />
            </div>
          </>
        ) : (
          <div className="fixed md:relative bottom-0 left-0 right-0 bg-primary border-t border-secondary-1000 md:border-0 flex w-full gap-6 justify-between items-center p-4">
            <Button
              styleString={"secondary"}
              type="button"
              disabled={isFirstSection}
              children={!isFirstSection ? "Back" : "Cancel"}
              {...(!isFirstSection && { icon: "ChevronLeftOutlined" })}
              onClick={goToPreviousSection}
            />
            {!currentUser.access.includes("worker") ? (
              <Button
                styleString={"primary"}
                type="button"
                children={!isLastSection ? "Next" : "Close Out"}
                {...(!isLastSection && { icon: "ChevronRightOutlined" })}
                onClick={!isLastSection ? goToNextSection : handleCloseOut}
                disabled={isLastSection && !canCloseOut}
                reverse={true}
              />
            ) : (
              !isLastSection && (
                <Button
                  styleString={"bold"}
                  type="button"
                  children={"Next"}
                  icon={"ChevronRightOutlined"}
                  onClick={goToNextSection}
                  reverse={true}
                />
              )
            )}
          </div>
        )}
      </div>
    </form>
  );
};

export default TaskInstanceForm;
