import { memo, useState } from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import { LabelOutlined } from "@mui/icons-material";
import { TrashIcon } from "@heroicons/react/24/outline";
import Button from "../general/Button";
import Input from "../general-ui/Input";
import Select from "react-select";
import { Controller } from "react-hook-form";
import { capitalizeString } from "../../utils/FormatFunctions";
import { FormInput } from "../../config/recurringTaskFormConfig";
import FormField from "./FormField";
import { NewRecurringTaskFormData } from "./RecurringTaskForm";
import { newInputSchema } from "../../validation/recurringTaskValidation";
import * as Yup from "yup";

interface FormContentProps {
  formInputs: FormInput[];
  attachmentOptions: { value: string; label: string }[];
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  updateShowModalState: (modal: string, value: boolean) => void;
  onSubmit: (data: NewRecurringTaskFormData) => Promise<void>;
}

interface InputField {
  id: string;
  name: string;
  dataType: string;
  validator: {
    required: boolean;
    lowerLimit?: string | number;
    upperLimit?: string | number;
  };
  isDeleted: boolean;
}

const FormContent = memo(
  ({
    formInputs,
    attachmentOptions,
    isEditing,
    setIsEditing,
    updateShowModalState,

    onSubmit,
  }: FormContentProps) => {
    const {
      register,
      control,
      formState: { errors },
      handleSubmit,
      watch,
      setValue,
      getValues,
      setError,
      clearErrors,
    } = useFormContext<NewRecurringTaskFormData>();

    const { fields, append, remove } = useFieldArray<NewRecurringTaskFormData>({
      control,
      name: "inputs",
    });

    const newDataType = watch("newInput.dataType");
    const formValues = getValues();

    const [editingInputIndex, setEditingInputIndex] = useState<number | null>(
      null
    );

    const handleEditInput = (index: number) => {
      const activeInputs = fields.filter((input) => !input.isDeleted);
      const inputToEdit = activeInputs[index];

      setValue("newInput", {
        name: decodeURIComponent(inputToEdit.name),
        dataType: inputToEdit.dataType,
        validator: {
          required: inputToEdit.validator.required ?? false,
          lowerLimit: inputToEdit.validator.lowerLimit,
          upperLimit: inputToEdit.validator.upperLimit,
        },
      });
      setEditingInputIndex(index);
    };

    const handleAddInput = async () => {
      let newNameInput: string = getValues("newInput.name").trim();
      const newInput = {
        name: newNameInput,
        dataType: getValues("newInput.dataType"),
        validator: {
          required: getValues("newInput.validator.required") ?? false,
          ...(getValues("newInput.dataType") === "number" && {
            lowerLimit: getValues("newInput.validator.lowerLimit"),
            upperLimit: getValues("newInput.validator.upperLimit"),
          }),
        },
      };

      try {
        await newInputSchema.validate(newInput, { abortEarly: false });

        const lowerLimit = newInput.validator.lowerLimit;
        const upperLimit = newInput.validator.upperLimit;

        if (newInput.dataType === "number" && lowerLimit && upperLimit) {
          if (Number(lowerLimit) > Number(upperLimit)) {
            setError("newInput.validator.upperLimit", {
              type: "manual",
              message: "Upper limit must be greater than lower limit",
            });
            return;
          }
        }

        if (editingInputIndex !== null) {
          // Update existing input
          const updatedFields = [...fields];
          updatedFields[editingInputIndex] = {
            ...updatedFields[editingInputIndex],
            ...newInput,
          };
          setValue("inputs", updatedFields);
          setEditingInputIndex(null);
        } else {
          // Add new input
          append({
            ...newInput,
            isDeleted: false,
          });
        }

        clearErrors("inputs");

        setValue("newInput", {
          name: "",
          dataType: "",
          validator: {
            required: true,
          },
        });
      } catch (validationError) {
        if (validationError instanceof Yup.ValidationError) {
          validationError.inner.forEach((error: Yup.ValidationError) => {
            const path = error.path?.replace(/^newInput\./, "");
            setError(`newInput.${path}` as keyof NewRecurringTaskFormData, {
              type: "manual",
              message: error.message,
            });
          });
        }
      }
    };

    const handleCancelEdit = () => {
      setEditingInputIndex(null);
      setValue("newInput", {
        name: "",
        dataType: "",
        validator: {
          required: true,
        },
      });
    };

    const handleDeleteInput = (index: number) => {
      formValues.inputs[index].isDeleted = true;
      setValue("inputs", formValues.inputs);
      remove(index);
    };

    const renderInputFields = fields
      .filter((input) => !input.isDeleted)
      .map((inputObject, index) => (
        <div
          key={inputObject.id}
          className="flex items-center w-full justify-between gap-2"
        >
          <div className="flex w-full justify-between text-sm items-start gap-2 bg-primary border border-secondary-1000 rounded p-1 my-1">
            <div className="flex items-start gap-2 min-w-0 flex-1">
              <LabelOutlined
                className="text-secondary-100 flex-shrink-0"
                fontSize="small"
              />
              <span className="break-all whitespace-pre-wrap overflow-hidden">
                {capitalizeString(decodeURIComponent(inputObject.name))}
              </span>
            </div>
            <span className="bg-secondary-1100 py-1 px-2 text-xs rounded-full flex-shrink-0 ml-2">
              {inputObject.dataType === "radio"
                ? "Pass/Fail"
                : inputObject.dataType === "radio-na"
                ? "Pass/Fail/Na"
                : capitalizeString(inputObject.dataType)}
            </span>
          </div>
          <div className="flex gap-2 flex-shrink-0">
            <button
              type="button"
              className="px-1"
              onClick={() => handleEditInput(index)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4 stroke-secondary-100 hover:stroke-secondary-100/75"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                />
              </svg>
            </button>
            <button
              type="button"
              className="px-1"
              onClick={() => handleDeleteInput(index)}
            >
              <TrashIcon className="h-4 w-4 stroke-reds hover:stroke-reds-400/75" />
            </button>
          </div>
        </div>
      ));

    return (
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="p-4 flex flex-col sm:max-h-[600px] max-w-3xl overflow-y-auto scrollbar-thin"
      >
        {formInputs.map((input) => (
          <FormField
            key={input.id}
            input={input}
            register={register}
            control={control}
            errors={errors}
            clearErrors={clearErrors}
          />
        ))}

        <div className="py-2 sm:grid sm:grid-cols-4">
          <label className="flex items-start text-sm font-medium leading-6 mb-2">
            Input Fields
          </label>

          <div className="col-span-3 bg-secondary-1200 rounded p-2">
            <div className="flex flex-col gap-2 my-2">
              <div className="flex gap-2 py-1">
                <Input
                  name="newInput.name"
                  label="Input Name"
                  showLabel={false}
                  placeholder="Input Name"
                  type="text"
                  register={register}
                  errors={errors.newInput?.name}
                />
                <Input
                  name="newInput.dataType"
                  label="Input Type"
                  showLabel={false}
                  placeholder="Select Type"
                  type="select"
                  options={[
                    { label: "Pass/Fail", value: "radio" },
                    { label: "Pass/Fail/Na", value: "radio-na" },
                    { label: "Number", value: "number" },
                    { label: "Text", value: "text" },
                    { label: "File", value: "file" },
                  ]}
                  register={register}
                  errors={errors.newInput?.dataType}
                />
              </div>

              {newDataType === "number" && (
                <div className="flex w-full gap-2 border-y border-secondary-1000 py-2">
                  <Input
                    // showLabel={false}
                    label="Lower Limit"
                    name="newInput.validator.lowerLimit"
                    placeholder="Lower Limit"
                    type="number"
                    control={control}
                    errors={errors.newInput?.validator?.lowerLimit}
                  />
                  <Input
                    // showLabel={false}
                    label="Upper Limit"
                    name="newInput.validator.upperLimit"
                    placeholder="Upper Limit"
                    type="number"
                    control={control}
                    errors={errors.newInput?.validator?.upperLimit}
                  />
                </div>
              )}
              <div className="flex w-full justify-end gap-2 items-center">
                <label className="text-sm text-secondary-500 ">
                  *Required field
                </label>
                <div className="w-fit">
                  <Input
                    name="newInput.validator.required"
                    label="Required"
                    type="switch"
                    showLabel={false}
                    control={control}
                    errors={errors.newInput?.validator?.required}
                  />
                </div>
              </div>
              <div className="flex w-full">
                <Button
                  icon={editingInputIndex !== null ? "Save" : "Add"}
                  children={
                    editingInputIndex !== null ? "Update Input" : "Add Input"
                  }
                  styleString="secondary"
                  type="button"
                  onClick={() => handleAddInput()}
                />
                {editingInputIndex !== null && (
                  <div className="ml-2">
                    <Button
                      icon="Cancel"
                      children="Cancel"
                      styleString="danger-outline"
                      type="button"
                      onClick={handleCancelEdit}
                    />
                  </div>
                )}
              </div>
            </div>
            {fields.length > 0 && <div>{renderInputFields}</div>}
            <div className="col-span-2">
              {errors.inputs && errors.inputs.message && (
                <p className="text-reds-500 text-sm mt-1">
                  {errors.inputs.message}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="sm:grid sm:grid-cols-3">
          <label className="block text-sm font-medium leading-6 mb-2">
            Attachments
          </label>

          <Controller
            name="attachments"
            control={control}
            defaultValue={[]}
            render={({ field }) => (
              <Select
                {...field}
                isMulti
                options={attachmentOptions}
                className="basic-multi-select col-span-2"
                classNamePrefix="select"
                placeholder="Select files (optional)"
                isClearable={false}
                onChange={(selected) => {
                  field.onChange(selected.map((item) => item.value));
                }}
                value={attachmentOptions.filter((option) =>
                  (field.value ?? []).includes(option.value)
                )}
                styles={{
                  input: (base) => ({
                    "input:focus": {
                      boxShadow: "none",
                    },
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "black",
                    primary25: "lightgray",
                  },
                })}
              />
            )}
          />
        </div>

        {isEditing && (
          <div className="flex justify-end gap-4 mt-4">
            <Button
              styleString="secondary"
              onClick={() => {
                updateShowModalState("createTask", false);
                setIsEditing(false);
              }}
              children="Cancel"
            />

            <Button
              styleString="primary"
              type="submit"
              children="Save Changes"
              icon="Save"
            />
          </div>
        )}
      </form>
    );
  }
);

FormContent.displayName = "FormContent";

export default FormContent;
