import React from "react";
import LogsList from "../../updatedcomponents/tables/Logslist";
import WorkOrderLogTable from "../../updatedcomponents/tables/WorkOrderLogTable";
import LogPrint from "../../updatedcomponents/modals/LogPrint";
import Pagination from "../../updatedcomponents/table-components/Pagination";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import FilterButton from "../../updatedcomponents/table-components/FilterButton";
import FilterModal from "../../updatedcomponents/table-components/FilterModal";
import {
  BuildOutlined,
  EngineeringOutlined,
  HealthAndSafetyOutlined,
} from "@mui/icons-material";
import ViewLog from "../../updatedcomponents/modals/ViewLog";
import { classNames } from "../../utils/FormatFunctions";
import Button from "../../updatedcomponents/general/Button";
import { useLog } from "../../hooks/useLog";
import { TabType } from "../../context/LogContext";
import Modal from "../../updatedcomponents/general-ui/Modal";
import { useFacility } from "../../hooks/useFacility";
import SearchInput from "../../updatedcomponents/general-ui/SearchBar";
import { useAuth } from "../../hooks/useAuth";
import Selector from "../../updatedcomponents/general-ui/Selector";
import WorkOrderDetails from "./WorkOrderDetails";
import { PrinterIcon, ShareIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";
import Drawer from "../../updatedcomponents/general-ui/Drawer";

const Logs: React.FC = () => {
  const {
    selectedTab,
    filters,
    showFilter,
    setShowFilter,
    updateTab,
    applyFilters,
    resetFilters,
    applyIndividualFilter,
    showState,
    updateShowState,
    selectedLog,
  } = useLog();

  const getFilterOptions = (selectedTab: TabType) => {
    switch (selectedTab) {
      case "work-orders":
        return [
          // {
          //   label: "Title",
          //   type: "text",
          //   name: "title",
          // },
          // { name: "id", label: "ID", type: "text" },
          {
            label: "Date Reported From",
            type: "date",
            name: "dateReportedFrom",
            joinId: "reportRange",
          },
          {
            label: "Date Reported To",
            type: "date",
            name: "dateReportedTo",
            joinId: "reportRange",
          },
          {
            label: "Date Closed From",
            type: "date",
            name: "dateClosedFrom",
            joinId: "dateRange",
          },
          {
            label: "Date Closed To",
            type: "date",
            name: "dateClosedTo",
            joinId: "dateRange",
          },
          {
            label: "Location",
            type: "text",
            name: "location",
          },
          {
            name: "urgency",
            label: "Urgency",
            type: "selector",
            options: [
              { value: "", label: "All Urgencies" },
              { value: "standard", label: "Standard" },
              { value: "urgent", label: "Urgent" },
            ],
          },
        ];
      case "life-safety":
        return [
          // {
          //   label: "Title",
          //   type: "text",
          //   name: "title",
          // },
          {
            label: "Task Frequency",
            type: "selector",
            options: [
              { label: "All Frequencies", value: "" },
              { label: "Daily", value: "daily" },
              { label: "Weekly", value: "weekly" },
              { label: "Bi-weekly", value: "bi-weekly" },
              { label: "Monthly", value: "monthly" },
              { label: "Quarterly", value: "quarterly" },
              { label: "Semi-annually", value: "semi-annually" },
              { label: "Annually", value: "annually" },
              { label: "2 Year", value: "biennially" },
              { label: "3 Year", value: "3-year" },
              { label: "4 Year", value: "4-year" },
              { label: "5 Year", value: "5-year" },
            ],
            name: "frequency",
          },
          {
            label: "Data Source",
            type: "selector",
            options: [
              { label: "All Sources", value: "" },
              { label: "Internal", value: "internal" },
              { label: "External", value: "external" },
            ],
            name: "source",
          },
        ];
      case "prev-maint":
        return [
          // {
          //   label: "Title",
          //   type: "text",
          //   name: "title",
          // },
          {
            label: "Task Frequency",
            type: "selector",
            options: [
              { label: "All", value: "" },
              { label: "Daily", value: "daily" },
              { label: "Weekly", value: "weekly" },
              { label: "Bi-weekly", value: "bi-weekly" },
              { label: "Monthly", value: "monthly" },
              { label: "Quarterly", value: "quarterly" },
              { label: "Semi-annually", value: "semi-annually" },
              { label: "Annually", value: "annually" },
              { label: "2 Year", value: "biennially" },
              { label: "3 Year", value: "3-year" },
              { label: "4 Year", value: "4-year" },
              { label: "5 Year", value: "5-year" },
            ],
            name: "frequency",
          },
          {
            label: "Data Source",
            type: "selector",
            options: [
              { label: "All", value: "" },
              { label: "Internal", value: "internal" },
              { label: "External", value: "external" },
            ],
            name: "source",
          },
        ];
      default:
        return [];
    }
  };

  const navigation = [
    {
      name: "Work Orders",
      value: "work-orders",
      current: selectedTab === "work-orders",
      icon: <BuildOutlined style={{ fontSize: "1rem" }} />,
    },
    {
      name: "Life Safety",
      value: "life-safety",
      current: selectedTab === "life-safety",
      icon: <HealthAndSafetyOutlined style={{ fontSize: "1rem" }} />,
    },
    {
      name: "Preventative Maintenance",
      value: "prev-maint",
      current: selectedTab === "prev-maint",
      icon: <EngineeringOutlined style={{ fontSize: "1rem" }} />,
    },
  ];
  const { selectedFacility } = useFacility();
  const { currentUser } = useAuth();
  const facility = currentUser?.facility.find(
    (facility) => facility._id === selectedFacility
  );
  return (
    <div className="flex flex-col h-full bg-primary">
      <PageHeader
        title={
          <>
            <span className="inline-flex gap-2">{facility?.name}</span>
          </>
        }
        mobileTitle={
          <>
            <span className="inline-flex gap-2">Logs</span>
          </>
        }
        subtitle={
          <div className="flex gap-2 items-center">
            View all closed tasks and work orders for your facility.
          </div>
        }
        buttons={
          <div className="flex flex-col sm:flex-row w-full h-fit justify-evenly sm:gap-4 gap-2 bg-primary">
            <SearchInput
              searchTerm={filters.q || ""}
              handleSearchChange={(searchTerm) =>
                applyIndividualFilter("q", searchTerm)
              }
            />
            <Button
              styleString="secondary"
              onClick={() => updateShowState("showPrintState", true)}
              icon="PrintOutlined"
              children=""
              overrideStyles="w-full"
            />
          </div>
        }
      />

      <div className={`px-4`}>
        <nav className="flex w-fit border rounded-lg my-4 bg-secondary-1100 divide-x divide-secondary-1000">
          {navigation.map((navItem, index) => (
            <div className="flex" key={index}>
              <button
                onClick={() =>
                  updateTab(
                    navItem.value as
                      | "work-orders"
                      | "life-safety"
                      | "prev-maint"
                  )
                }
                className={classNames(
                  navItem.current
                    ? "bg-primary text-secondary-100 shadow translate-y-[1px]"
                    : "text-secondary-100 hover:bg-secondary-1200 hover:shadow-sm hover:-translate-y-[1px]",
                  "m-1 group flex items-center flex-1 rounded-lg text-xs sm:text-sm leading-6 py-2.5 px-3 font-normal sm:whitespace-nowrap",
                  "transition-all duration-300 ease-in-out",
                  "transform",
                  "hover:shadow-lg",
                  "active:translate-y-[1px]"
                )}
                style={{
                  transitionProperty: "all",
                  transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
                  transitionDuration: "300ms",
                }}
              >
                <span className="flex align-middle mr-2 transition-transform duration-300 ease-in-out transform group-hover:scale-110">
                  {navItem.icon}
                </span>
                <span className="transition-all duration-300 ease-in-out">
                  {navItem.name}
                </span>
              </button>
            </div>
          ))}
        </nav>
      </div>
      <div className="hidden lg:flex border-b border-secondary-1000 w-full justify-between items-center px-4 pb-3">
        <div className="flex gap-2 ">
          {getFilterOptions(selectedTab)
            .filter((option) => option.type === "selector")
            .map((filterOption) => (
              <Selector
                className="flex-1 flex "
                key={filterOption.name}
                placeholder={filterOption.label}
                options={filterOption.options || []}
                value={
                  filters[filterOption.name as keyof typeof filters] !==
                  undefined
                    ? filterOption.options?.find(
                        (option) =>
                          option.value ===
                          filters[filterOption.name as keyof typeof filters]
                      )
                    : filterOption.options?.[0]
                }
                onChange={(selectedOption) =>
                  applyIndividualFilter(
                    filterOption.name,
                    String(selectedOption.value)
                  )
                }
              />
            ))}
        </div>
        <div className="w-fit">
          <Button
            styleString="secondary"
            children="Reset Filters"
            icon="Refresh"
            onClick={resetFilters}
          />
        </div>
      </div>

      <FilterModal
        show={showFilter}
        setShow={setShowFilter}
        filters={JSON.stringify(filters)}
        filterOptions={getFilterOptions(selectedTab)}
        applyFilters={applyFilters}
        resetFilters={resetFilters}
      />
      {selectedTab !== "work-orders" && <LogsList />}

      {selectedTab === "work-orders" && (
        <>
          <WorkOrderLogTable />
        </>
      )}
      <Modal
        title={selectedLog?.title || "View Log"}
        open={showState.showLogState}
        setOpen={() => updateShowState("showLogState", false)}
        content={<ViewLog />}
      />
      <Modal
        title="Print Logs"
        open={showState.showPrintState}
        setOpen={() => updateShowState("showPrintState", false)}
        content={<LogPrint />}
      />
      <Drawer
        open={showState.showWorkOrder}
        setOpen={() => updateShowState("showWorkOrder", false)}
        title={
          <>
            <button
              onClick={() => {}}
              data-tooltip-id="share-tooltip"
              data-tooltip-content="Work order copied to clipboard"
              onMouseEnter={(e) => {
                e.currentTarget.setAttribute("data-tooltip-show", "false");
              }}
            >
              <ShareIcon
                className="h-6 w-6 text-secondary-300"
                aria-hidden="true"
              />
            </button>
            <Tooltip
              id="share-tooltip"
              place="top"
              delayHide={2000}
              openOnClick={true}
            />
          </>
        }
        content={
          <WorkOrderDetails
           />
        }
      />
    </div>
  );
};

export default Logs;
