import React from "react";
import NewWorkOrder from "../../updatedcomponents/forms/WorkOrderForm";
import { useWorkOrder } from "../../hooks/useWorkOrder";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

const CreateWorkOrder = () => {
  const { updateModalShowState, searchParams } = useWorkOrder();
  const navigate = useNavigate();
  return (
    <div className="bg-primary">
      <PageHeader
        title={""}
        mobileTitle={
          <>
            <span
              onClick={() => navigate(`/work-orders?${searchParams}`)}
              className="inline-flex items-center gap-2"
            >
              <button className="cursor-pointer">
                <ChevronLeftIcon className="h-5 w-5" />
              </button>
              <span>New Work Order</span>
            </span>
          </>
        }
      />
      <div className="p-5">
        <p className=" text-secondary-300 font-light text-sm">
          Get started by filling in the information below to create the new work
          order.
        </p>
        <NewWorkOrder
          setShowPopup={() => updateModalShowState("showNewWorkOrder", false)}
        />
      </div>
    </div>
  );
};

export default CreateWorkOrder;
