import { InputType } from "../updatedcomponents/general-ui/Input";

export interface FormInput {
  id: string;
  type: InputType;
  label: string;
  options?: { value: string; label: string }[];
  validator?: {
    lowerLimit?: number | null;
    upperLimit?: number | null;
    required?: boolean;
    requireNote?: boolean;
  };
  isDeleted?: boolean;
}

export const getFormInputs = (maps: any[], vendors: any[]): FormInput[] => [
  {
    id: "title",
    label: "Task Title",
    type: "text",
  },
  {
    id: "taskType",
    label: "Task Type",
    type: "select",
    options: [
      { value: "life-safety", label: "Life Safety" },
      { value: "prev-maint", label: "Preventative Maintenance" },
    ],
  },
  {
    id: "category",
    label: "Category",
    type: "select",
    options: [
      { value: "fire safety", label: "Fire Safety" },
      { value: "electrical safety", label: "Electrical Safety" },
      { value: "operational safety", label: "Operational Safety" },
      { value: "gas safety", label: "Gas/Air Safety" },
    ],
  },
  {
    id: "recurrence.frequency",
    label: "Frequency",
    type: "select",
    options: [
      { value: "daily", label: "Daily" },
      { value: "weekly", label: "Weekly" },
      { value: "bi-weekly", label: "Biweekly" },
      { value: "monthly", label: "Monthly" },
      { value: "quarterly", label: "Quarterly" },
      { value: "semi-annually", label: "Semi-Annually" },
      { value: "annually", label: "Annually" },
      { value: "biennially", label: "2-Year" },
      { value: "3-year", label: "3-Year" },
      { value: "4-year", label: "4-Year" },
      { value: "5-year", label: "5-Year" },
    ],
  },
  {
    id: "skipWeekends",
    label: "Skip Weekends",
    type: "switch",
  },
  {
    id: "skippable",
    label: "Allow Task Skipping",
    type: "switch",
  },
  {
    id: "source",
    label: "Source",
    type: "select",
    options: [
      { value: "internal", label: "Internal" },
      { value: "external", label: "External" },
    ],
  },
  {
    id: "details",
    label: "Task Details",
    type: "textarea",
  },
  {
    id: "startDate",
    label: "Start Date",
    type: "date",
  },
  {
    id: "endDate",
    label: "End Date",
    type: "date",
  },
  {
    id: "map",
    label: "Map",
    type: "select",
    options: [
      { value: "", label: "No Map" },
      ...maps.map((map) => ({
        value: map._id,
        label: map.name,
      })),
    ],
  },
];

export const formSteps = (isEditing: boolean) => [
  {
    id: "01",
    name: isEditing ? "Review Current" : "Select Template",
    stepCount: 1,
    current: true,
    disabled: false,
  },
  {
    id: "02",
    name: isEditing ? "Make Changes" : "Customize Template",
    stepCount: 2,
    current: false,
    disabled: false,
  },
  { 
    id: "03", 
    name: "Preview", 
    stepCount: 3, 
    current: false, 
    disabled: true 
  },
]; 