import { Fragment } from "react";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import {
  ArrowBack,
  CalendarMonthOutlined,
  CheckCircleOutlined,
  ChecklistOutlined,
  Close,
  DeleteOutline,
  Email,
  EmailOutlined,
  Person,
  PhoneOutlined,
  RemoveCircleOutlineOutlined,
  Sms,
  Verified,
  Warning,
  WorkOutline,
} from "@mui/icons-material";
import MirrorDensityPlot from "../../updatedcomponents/data-display/MirrorDensityPlot";
import StatusBadge from "../../updatedcomponents/general-ui/StatusBadges";
import {
  capitalizeString,
  formatDate,
  formatPhoneNumber,
} from "../../utils/FormatFunctions";
import GenericModal from "../../updatedcomponents/modals/GenericModal";
import { WorkOrder } from "../../types/WorkOrder";
import { useAuth } from "../../hooks/useAuth";
import { useTeam } from "../../hooks/useTeam";
import { TaskInstance } from "../../types/TaskInstance";
import Widget from "../../updatedcomponents/data-display/Widget";
import { useNavigate } from "react-router-dom";
import Spinner from "../../updatedcomponents/general/Spinner";

const UserPage = () => {
  const {
    selectedUser,
    modalShowState,
    updateModalShowState,
    userWorkOrders,
    userTasks,
    workOrderHistory,
    handleViewTask,
    handleDeleteUser,
  } = useTeam();
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const permissions = currentUser.access;

  const assignedWorkOrderTable = (
    tasks: WorkOrder[] | TaskInstance[],
    type: string,
    message: string
  ) => (
    <div className="flex-1 px-4 sm:px-6  border border-secondary-1000 rounded-lg  h-96 overflow-y-scroll  hide-scrollbar-arrows">
      <h2 className="py-4 text-md text-secondary-300 font-semibold">
        Assigned {type}
      </h2>
      <table className="min-w-full">
        <thead>
          <tr className="bg-secondary-1200 border border-secondary-1000 border-spacing-2">
            <th
              scope="col"
              className="py-1.5 pl-4 pr-3 text-left text-sm font-semibold text-secondary-100 "
            >
              Title
            </th>
            <th
              scope="col"
              className="px-3 py-1.5 text-left text-sm font-semibold text-secondary-100"
            >
              ID
            </th>
            <th
              scope="col"
              className="px-3 py-1.5 text-left text-sm font-semibold text-secondary-100"
            >
              Status
            </th>
            <th scope="col" className="relative py-0.5 pl-3 pr-4 sm:pr-6">
              <span className="sr-only">View</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {tasks.length === 0 ? (
            <Fragment>
              <tr className="h-2"></tr>
              <tr>
                <td
                  colSpan={4}
                  className="text-center text-md py-8 bg-secondary-1200 border border-secondary-1000"
                >
                  <div className="flex flex-col items-center justify-center text-sm gap-2 text-secondary-400">
                    <ChecklistOutlined style={{ fontSize: "2rem" }} />
                    {message}
                  </div>
                </td>
              </tr>
            </Fragment>
          ) : (
            tasks.map((task) => (
              <Fragment key={`${task._id}-${type}`}>
                <tr key={`${task._id}-gap`} className="h-2"></tr>
                <tr
                  key={task._id}
                  className=" border border-secondary-1000 text-secondary-100"
                >
                  <td className="truncate max-w-12 whitespace-nowrap py-1.5 pl-4 pr-3 text-sm font-medium text-secondary-100 ">
                    {task.title}
                  </td>
                  <td className="whitespace-nowrap px-3 py-1.5 text-sm text-secondary-500">
                    {task?.shortId}
                  </td>
                  <td className="whitespace-nowrap px-3 py-1.5 text-sm text-secondary-500">
                    {<StatusBadge status={task.status} />}
                  </td>
                  <td className="whitespace-nowrap px-3 py-1.5 text-sm ">
                    <button
                      onClick={() => handleViewTask(task, type)}
                      className=" px-2 py-1 text-sm font-semibold text-secondary-100 underline  hover:text-secondary-300"
                    >
                      View
                    </button>
                  </td>
                </tr>
              </Fragment>
            ))
          )}
        </tbody>
      </table>
    </div>
  );

  const assignmentChart = (
    <div className="flex-1 px-4 my-4">
      <MirrorDensityPlot data={workOrderHistory} />
    </div>
  );

  const deactivate = (
    <div className="h-14 px-4   flex-1 flex  items-center gap-2 m-full border-t border-secondary-1000">
      <button
        onClick={() => updateModalShowState("showDelete", true)}
        className="bg-reds-500 text-primary text-sm hover:bg-reds-600 px-2 py-1 rounded  shadow cursor-pointer flex items-center gap-2"
      >
        <DeleteOutline style={{ fontSize: "1rem" }} />
        Delete user
      </button>
      <p className="text-reds-500 text-sm">This action can not be undone</p>
    </div>
  );

  return (
    <div className="bg-primary">
      <PageHeader
        title={
          <button
            type="button"
            className="flex gap-2 items-center text-md font-normal text-secondary-300 hover:text-secondary-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-100 "
            onClick={() => window.history.back()}
          >
            <ArrowBack style={{ fontSize: "1rem" }} /> Back to Team
          </button>
        }
        subtitle={""}
        mobileTitle={
          <button
            type="button"
            className="flex gap-2 items-center text-md font-normal text-secondary-300 hover:text-secondary-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-100 "
            onClick={() => window.history.back()}
          >
            <ArrowBack style={{ fontSize: "1rem" }} /> Back to Team
          </button>
        }
        buttons={<></>}
      />
      {selectedUser ? (
        <div>
          {selectedUser ? (
            <div className=" my-4 grid grid-cols-1 md:grid-cols-2 w-full">
              {/* First column */}

              <div className="my-8 col-span-full md:col-span-1 flex flex-col sm:flex-row gap-4 justify-center items-center border-r-2 border-secondary-1000 md:border-r">
                <div className="flex flex-col items-end justify-start h-full">
                  <div className="flex-shrink-0 ">
                    {selectedUser.avatar ? (
                      <img
                        className="h-16 w-16 sm:h-8 sm:w-8  rounded-full"
                        src={selectedUser.avatar}
                        alt=""
                      />
                    ) : (
                      <Person className="h-11 w-11 rounded-full" />
                    )}
                  </div>
                </div>
                <div className="flex flex-col items-center sm:items-start">
                  <h2 className="text-2xl">
                    {capitalizeString(selectedUser.firstname)}{" "}
                    {capitalizeString(selectedUser.lastname)}
                  </h2>
                  <div className="my-2.5 gap-y-1 flex flex-col w-full">
                    <div className="flex gap-2 justify-center sm:justify-start mb-4 sm:mb-1">
                      {selectedUser.isActive === true ? (
                        <div className="w-fit inline-flex items-center rounded-lg bg-accent-1000 px-2 py-1 text-xs font-medium text-accent-200 ring-1 ring-inset ring-accent-200/20">
                          <CheckCircleOutlined
                            className="mr-1"
                            style={{ fontSize: ".9rem" }}
                          />{" "}
                          Active
                        </div>
                      ) : (
                        <div className="w-fit inline-flex items-center rounded-lg bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-green-600/20">
                          <Close
                            className="mr-1"
                            style={{ fontSize: ".9rem" }}
                          />{" "}
                          Inactive
                        </div>
                      )}
                      {selectedUser.isVerified === true ? (
                        <div className="w-fit inline-flex items-center rounded-lg bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                          <Verified
                            className="mr-1"
                            style={{ fontSize: ".9rem" }}
                          />{" "}
                          Verified
                        </div>
                      ) : (
                        <div className="w-fit inline-flex items-center rounded-lg bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-green-600/20">
                          <Verified
                            className="mr-1"
                            style={{ fontSize: ".9rem" }}
                          />{" "}
                          Unverified
                        </div>
                      )}
                    </div>
                    <div className="text-secondary-500 text-sm flex items-center gap-2">
                      <EmailOutlined style={{ fontSize: "1rem" }} />{" "}
                      {selectedUser.email}
                    </div>
                    <div className="text-secondary-500 text-sm flex items-center gap-2">
                      <PhoneOutlined style={{ fontSize: "1rem" }} />{" "}
                      {formatPhoneNumber(selectedUser.phone)}
                    </div>
                    <div className="text-secondary-500 text-sm flex items-center gap-2">
                      <WorkOutline style={{ fontSize: "1rem" }} />{" "}
                      {capitalizeString(selectedUser.access)}
                    </div>
                    <div className="text-secondary-500 text-sm flex items-center gap-2">
                      <CalendarMonthOutlined style={{ fontSize: "1rem" }} />{" "}
                      {formatDate(selectedUser.joinDate, "YYYY-MM-DD h:mm a")}{" "}
                      <span className="text-xs">(Join Date)</span>
                    </div>
                    <div className="text-secondary-500 text-sm flex items-center gap-2">
                      {selectedUser.smsNotify === true ? (
                        <div className="w-fit inline-flex items-center rounded-lg bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                          <Sms className="mr-1" style={{ fontSize: ".9rem" }} />{" "}
                          SMS Notifications Enabled
                        </div>
                      ) : (
                        <div className="w-fit inline-flex items-center rounded-lg bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-green-600/20">
                          <RemoveCircleOutlineOutlined
                            className="mr-1"
                            style={{ fontSize: ".9rem" }}
                          />{" "}
                          SMS Notifications Disabled
                        </div>
                      )}
                    </div>
                    <div className="text-secondary-500 text-sm flex items-center gap-2">
                      {selectedUser.emailNotify === true ? (
                        <div className="w-fit inline-flex items-center rounded-lg bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                          <Email
                            className="mr-1"
                            style={{ fontSize: ".9rem" }}
                          />
                          Email Notifications Enabled
                        </div>
                      ) : (
                        <div className="w-fit inline-flex items-center rounded-lg bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-green-600/20">
                          <RemoveCircleOutlineOutlined
                            className="mr-1"
                            style={{ fontSize: ".9rem" }}
                          />{" "}
                          Email Notifications Disabled
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* Second column: Rest of the Information */}
              <div className="md:col-span-1 flex flex-col items-center sm:items-start sm:justify-start my-8 sm:ml-14 gap-y-2">
                <div className="flex sm:flex-col flex-row w-fit  px-4 gap-4">
                  <Widget
                    color="bg-secondary-1000"
                    status="Assigned Work Orders"
                    count={userWorkOrders.length}
                    icon={<WorkOutline />}
                    variant={"default"}
                    onClick={() => {
                      navigate(
                        `/work-orders?filters={"assignedTo":"${selectedUser._id}"}`
                      );
                    }}
                  />
                  <Widget
                    color="bg-secondary-100"
                    status="Assigned Recurring Tasks"
                    count={userTasks.length}
                    icon={<WorkOutline />}
                    variant={"default"}
                    onClick={() => {
                      navigate(
                        `/tasks?filters={"assignedTo":"${selectedUser._id}"}`
                      );
                    }}
                  />
                </div>
              </div>

              <div className="mt-6 mx-1 md:col-span-2 border-b border-secondary-1000" />
            </div>
          ) : (
            <Spinner />
          )}

          {/* <div className="flex gap-4 flex-col md:flex-row mx-4">
            {assignedWorkOrderTable(
              userWorkOrders,
              "Work Orders",
              "No work orders found"
            )}
            {assignedWorkOrderTable(
              userTasks,
              "Recurring Tasks",
              "No recurring tasks found"
            )}
          </div> */}
          <div className="flex">{assignmentChart}</div>
          <div className="flex">
            {["regional", "organization-admin"].includes(permissions) &&
              deactivate}
          </div>

          <GenericModal
            title={"Delete Account"}
            handleFunction={handleDeleteUser}
            showModal={modalShowState.showDelete}
            setShowModal={() => updateModalShowState("showDelete", false)}
            buttonText={"Delete"}
            ModalIcon={Warning}
            item={selectedUser}
          />
        </div>
      ) : (
        <div className="flex flex-col flex-grow justify-center items-center h-full">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default UserPage;
