import React, { Fragment, useState } from "react";
import axios from "axios";
import { DeleteOutline, SearchOffOutlined } from "@mui/icons-material";
import GenericModal from "../modals/GenericModal";
import { capitalizeString, formatDate } from "../../utils/FormatFunctions";
import { RecurringTask } from "../../types/RecurringTask";
import { useTaskScheduler } from "../../hooks/useTaskScheduler";
import Table from "../general/Table";
import Spinner from "../general/Spinner";
import { useDeviceWidth } from "../../hooks/useDeviceWidth";
import {
  BookmarkIcon,
  CalendarDaysIcon,
  RectangleGroupIcon,
  Squares2X2Icon,
} from "@heroicons/react/24/outline";

type PageType = "life-safety" | "prev-maint" | "administrative";
interface RecurringTasksProps {
  recurringTasks: RecurringTask[];
}

const RecurringTasks: React.FC<RecurringTasksProps> = ({ recurringTasks }) => {
  const { handleViewClick, isLoading, currentSort, sortDirection, updateSort } = useTaskScheduler();
  const { isMobile } = useDeviceWidth();
  const returnTitle = (type: PageType) => {
    switch (type) {
      case "life-safety":
        return "Life Safety";
      case "prev-maint":
        return "Preventative Maintenance";
      case "administrative":
        return "Administrative";
      default:
        return "Recurring Tasks";
    }
  };
  const columns = [
    {
      label: "Title",
      key: "title",
      sortable: true,
      render: (recurringTask: RecurringTask) => (
        <>
          <div className="sm:hidden bg-primary px-4 flex flex-col gap-1">
            <div className="flex flex-col gap-2 py-3">
              <span className="text-secondary-100 font-semibold text-sm">
                {capitalizeString(recurringTask.title)}
              </span>
              <span className="text-secondary-300 font-light text-sm line-clamp-2">
                {capitalizeString(recurringTask.details)}
              </span>
            </div>
            <div className="flex justify-between border-t border-secondary-1000 -mx-4 px-4 py-3">
              <div className="flex flex-col gap-3">
                <span className="flex items-center gap-2 text-secondary-300 font-light text-sm w-fit">
                  <Squares2X2Icon className="w-4 h-4" />
                  {capitalizeString(recurringTask.category)}
                </span>
                <span className="flex items-center gap-2 text-secondary-300 font-light text-sm border border-secondary-1000 bg-secondary-1100 rounded-md px-2 py-1 w-fit">
                  <CalendarDaysIcon className="w-4 h-4" />
                  {capitalizeString(recurringTask.recurrence.frequency)}
                </span>
              </div>
              <div className="flex flex-col gap-3">
                <span className="flex items-center gap-2 text-secondary-300 font-light text-sm w-fit">
                  <BookmarkIcon className="w-4 h-4" />
                  {capitalizeString(recurringTask.source)}
                </span>
                <span className="flex items-center gap-2 text-secondary-300 font-light text-sm  w-fit">
                  {formatDate(recurringTask.startDate, "MMM DD, YYYY", "utc")}
                </span>
              </div>
            </div>
          </div>
          <span className="hidden sm:block">
            {capitalizeString(recurringTask.title)}
          </span>
        </>
      ),
      className: "",
    },

    {
      label: "Type",
      key: "taskType",
      sortable: true,
      render: (recurringTask: RecurringTask) => (
        <div className="flex flex-col">
          <span>{returnTitle(recurringTask.taskType as PageType)}</span>
        </div>
      ),
      className: "hidden sm:table-cell truncate",
    },
    {
      label: "Frequency",
      key: "frequency",
      sortable: true,
      render: (recurringTask: RecurringTask) => (
        <div className="flex flex-col">
          <span>{capitalizeString(recurringTask.recurrence.frequency)}</span>
        </div>
      ),
      className: "hidden sm:table-cell truncate",
    },
    {
      label: "Category",
      key: "category",
      sortable: true,
      render: (recurringTask: RecurringTask) => (
        <div className="flex flex-col">
          <span>{capitalizeString(recurringTask.category)}</span>
        </div>
      ),
      className: "hidden sm:table-cell truncate",
    },

    {
      label: "Start Date",
      key: "startDate",
      sortable: false,
      render: (recurringTask: RecurringTask) => (
        <>
          {recurringTask.startDate &&
            formatDate(recurringTask.startDate, "MMM DD, YYYY", "utc")}
        </>
      ),
      className: "hidden sm:table-cell truncate",
    },

    {
      label: "Source",
      key: "source",
      sortable: false,
      render: (recurringTask: RecurringTask) => (
        <>{capitalizeString(recurringTask.source)}</>
      ),
      className: "hidden sm:table-cell truncate",
    },
  ];

  return (
    <div className="flex-grow overflow-hidden">
      <Table
        columns={columns}
        data={recurringTasks}
        onRowClick={(task) => handleViewClick(task)}
        paginationScheme="none"
        showHeader={isMobile ? false : true}
        handleSort={updateSort}
        sortColumn={currentSort}
        sortDirection={sortDirection}
        noDataMessage={
          isLoading ? (
            <Spinner size="xl" />
          ) : (
            <div className="flex flex-col gap-4 items-center justify-center">
              <img src="/no-data.svg" className="w-1/2" />
              <span className="text-secondary-300 text-sm sm:text-md">
                No matching tasks.
              </span>
            </div>
          )
        }
      />
    </div>
  );
};

export default RecurringTasks;
