import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useFacility } from "../hooks/useFacility";
import { debounce } from "../utils/Helpers";
import { RecurringTask } from "../types/RecurringTask";
import {
  createRecurringTask,
  deleteRecurringTask,
  fetchDocuments,
  fetchMaps,
  fetchRecurringTasks,
  fetchTemplates,
  fetchVendors,
  updateRecurringTask,
} from "../api/services/RecurringTaskService";
import { TaskTemplate } from "../types/TaskTemplate";
import { File } from "../types/File";
import { Map } from "../types/Map";
import { Vendor } from "../types/Vendor";
import { NewRecurringTaskFormData } from "../updatedcomponents/forms/RecurringTaskForm";
import { useToast } from "../hooks/useToast";
import {
  CheckBadgeIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/20/solid";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDeviceWidth } from "../hooks/useDeviceWidth";

interface Filters {
  q: string;
  sort: string;
  category: string;
  taskType: string;
  frequency: string;
}

// Define the type for the context values
interface TaskSchedulerContextType {
  tasks: RecurringTask[];
  selectedTask: RecurringTask | null;
  templates: TaskTemplate[];
  attachments: File[];
  maps: Map[];
  vendors: Vendor[];
  showModalState: Record<string, boolean>;
  updateShowModalState: (modal: string, value: boolean) => void;
  isLoading: boolean;
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  createTask: (task: NewRecurringTaskFormData) => void;
  updateTask: (taskId: string, task: NewRecurringTaskFormData) => void;
  handleDeleteClick: (task: RecurringTask) => void;
  handleDeleteTask: (task: RecurringTask) => void;
  handleViewClick: (task: RecurringTask) => void;
  sortTasks: (sortBy: string) => void;
  currentSort: string;
  sortDirection: string;
  updateSort: (value: string) => void;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
}


// Define the type for the provider props
interface TaskSchedulerProviderProps {
  children: ReactNode;
}

// Create the context
export const TaskSchedulerContext = createContext<
  TaskSchedulerContextType | undefined
>(undefined);

// Create the provider component
export const TaskSchedulerProvider = ({
  children,
}: TaskSchedulerProviderProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { selectedFacility } = useFacility();
  const navigate = useNavigate();
  const { isMobile } = useDeviceWidth();
  const { showResponse } = useToast();
  const [tasks, setTasks] = useState<RecurringTask[]>([]);
  const [filteredTasks, setFilteredTasks] = useState<RecurringTask[]>([]);

  const [selectedTask, setSelectedTask] = useState<RecurringTask | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [showModalState, setShowModalState] = useState({
    onboarding: false,
    createTask: false,
    deleteTask: false,
    viewTask: false,
  });

  const [templates, setTemplates] = useState<TaskTemplate[]>([]);
  const [attachments, setAttachments] = useState<File[]>([]);
  const [maps, setMaps] = useState<Map[]>([]);
  const [vendors, setVendors] = useState<Vendor[]>([]);

  // Get current sort from URL params
  const currentSort = searchParams.get("sort") || "none";
  const sortDirection = searchParams.get("direction") || "1";

  const updateSort = (value: string) => {
    setSearchParams((prev) => {
      // If clicking the same column, toggle direction
      if (value === currentSort) {
        const newDirection = sortDirection === "1" ? "-1" : "1";
        prev.set("direction", newDirection);
      } else {
        // New column, set default ascending direction
        prev.set("direction", "1");
        if (value === "none") {
          prev.delete("sort");
          prev.delete("direction");
        } else {
          prev.set("sort", value);
        }
      }
      return prev;
    });
    sortTasks(value);
  };

  const updateShowModalState = (modal: string, value: boolean) => {
    setShowModalState((prev) => ({ ...prev, [modal]: value }));
  };

  // Separate function to load static data
  const loadStaticData = useCallback(async () => {
    try {
      if (!selectedFacility) return;

      const [template, attachments, maps, vendors] = await Promise.all([
        fetchTemplates(),
        fetchDocuments(selectedFacility),
        fetchMaps(selectedFacility),
        fetchVendors(selectedFacility),
      ]);

      setTemplates(template);
      setAttachments(attachments);
      setMaps(maps);
      setVendors(vendors);
    } catch (error) {
      setTemplates([]);
      setAttachments([]);
      setMaps([]);
      setVendors([]);
      console.error("Error fetching data:", error);
    }
  }, [selectedFacility]);

  // Separate function to load tasks based on search
  const loadTasks = useCallback(async () => {
    try {
      setIsLoading(true);
      if (!selectedFacility) {
        return;
      }
      const data = await fetchRecurringTasks(selectedFacility, searchTerm);
      setTasks(data);

      // Apply initial sorting if sort parameter exists
      if (currentSort && currentSort !== "none") {
        const sorted = [...data].sort((a, b) => {
          if (currentSort === "title") {
            return a.title.localeCompare(b.title);
          } else if (currentSort === "frequency") {
            return a.recurrence.frequency.localeCompare(b.recurrence.frequency);
          } else if (currentSort === "category") {
            return (a.category || "").localeCompare(b.category || "");
          } else if (currentSort === "taskType") {
            return (a.taskType || "").localeCompare(b.taskType || "");
          }
          return 0;
        });
        setFilteredTasks(sorted);
      } else {
        setFilteredTasks(data);
      }
    } catch (error) {
      setTasks([]);
      setFilteredTasks([]);
      console.error("Error fetching tasks:", error);
    } finally {
      setIsLoading(false);
    }
  }, [searchTerm, selectedFacility, currentSort]);

  // Effect for loading static data once when facility changes
  useEffect(() => {
    if (!selectedFacility) return;
    loadStaticData();
  }, [selectedFacility]);

  // Effect for loading tasks when search term changes
  useEffect(() => {
    if (!selectedFacility) return;
    loadTasks();
  }, [searchTerm, selectedFacility]);

  const createTask = async (formData: NewRecurringTaskFormData) => {
    try {
      await createRecurringTask(formData);
      if (isMobile) {
        navigate(-1);
      } 
      loadTasks();
      setIsEditing(false);
      showResponse(
        "Task Created",

        "Task has been successfully Created",
        <CheckBadgeIcon className="h-6 w-6 text-accent-500" />
      );
    } catch (error) {
      showResponse(
        "Error",
        "There was an error creating the task",
        <ExclamationCircleIcon className="h-6 w-6 text-reds-500" />
      );
      console.error("Error creating the task:", error);
    }
  };

  const updateTask = async (
    taskId: string,
    formData: NewRecurringTaskFormData
  ) => {
    try {
      const updatedTask = await updateRecurringTask(taskId, formData);
      setSelectedTask(updatedTask);
      loadTasks();
      setIsEditing(false);
      if (isMobile) {
        navigate(-1);
      } else {
        updateShowModalState("viewTask", true);
      }
      showResponse(
        "Task Updated",
        "Task has been successfully updated",
        <CheckBadgeIcon className="h-6 w-6 text-accent-500" />
      );
    } catch (error) {
      showResponse(
        "Error",
        "There was an error updating the task",
        <ExclamationCircleIcon className="h-6 w-6 text-reds-500" />
      );
      console.error("Error updating task:", error);
    }
  };

  const handleDeleteClick = (task: RecurringTask) => {
    setSelectedTask(task);
    updateShowModalState("deleteTask", true);
  };

  const handleDeleteTask = async () => {
    try {
      if (!selectedTask) return;
      await deleteRecurringTask(selectedTask._id);
      loadTasks();
      updateShowModalState("deleteTask", false);
      updateShowModalState("viewTask", false);
      showResponse(
        "Task Deleted",
        "Task has been successfully deleted",
        <CheckBadgeIcon className="h-6 w-6 text-accent-500" />
      );
    } catch (error) {
      showResponse(
        "Error",
        "There was an error deleting the task",
        <ExclamationCircleIcon className="h-6 w-6 text-reds-500" />
      );
      console.error("Error deleting task:", error);
    }
  };

  const handleViewClick = (task: RecurringTask) => {
    setSelectedTask(task);
    isMobile
      ? navigate(`/task-scheduler/${task._id}`)
      : updateShowModalState("viewTask", true);
  };

  const sortTasks = (sortBy: string) => {
    if (sortBy === "none") {
      setFilteredTasks([...tasks]);
      return;
    }

    const sorted = [...filteredTasks].sort((a, b) => {
      let comparison = 0;
      if (sortBy === "title") {
        comparison = a.title.localeCompare(b.title);
      } else if (sortBy === "frequency") {
        comparison = a.recurrence.frequency.localeCompare(
          b.recurrence.frequency
        );
      } else if (sortBy === "category") {
        comparison = (a.category || "").localeCompare(b.category || "");
      } else if (sortBy === "taskType") {
        comparison = (a.taskType || "").localeCompare(b.taskType || "");
      }
      return sortDirection === "1" ? comparison : -comparison;
    });

    setFilteredTasks(sorted);
  };

  const [isEditing, setIsEditing] = useState(false);

  return (
    <TaskSchedulerContext.Provider
      value={{
        tasks: filteredTasks,
        templates,
        attachments,
        maps,
        vendors,
        selectedTask,
        showModalState,
        updateShowModalState,
        isLoading,
        searchTerm,
        setSearchTerm,
        createTask,
        updateTask,
        handleDeleteClick,
        handleDeleteTask,
        handleViewClick,
        sortTasks,
        currentSort,
        sortDirection,
        updateSort,
        isEditing,
        setIsEditing,
      }}
    >
      {children}

    </TaskSchedulerContext.Provider>
  );
};
