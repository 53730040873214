import React, { useEffect, useCallback, useState } from "react";
import { useRecurring } from "../../hooks/useRecurring";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import { ArrowBack } from "@mui/icons-material";
import TaskInstanceForm from "../../updatedcomponents/forms/TaskInstanceForm";
import { useNavigate, useParams } from "react-router-dom";
import useMultiForm from "../../hooks/useMultiForm";
import GenericModal from "../../updatedcomponents/modals/GenericModal";

const TaskInstanceFormPage = () => {
  const navigate = useNavigate();
  const { section } = useParams();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [showUnsavedModal, setShowUnsavedModal] = useState(false);
  const [targetNavigation, setTargetNavigation] = useState<{
    type: "section" | "back" | "next" | "previous";
    index?: number;
  } | null>(null);

  const { instanceToEdit, closeOutValidation } = useRecurring();

  const { currentIndex, goToSection, isFirstSection, isLastSection } =
    useMultiForm(instanceToEdit?.parentTask?.map?.fields ?? []);

  useEffect(() => {
    closeOutValidation();
  }, [closeOutValidation, currentIndex, instanceToEdit]);

  useEffect(() => {
    if (section !== undefined) {
      const sectionIndex = parseInt(section);
      if (!isNaN(sectionIndex)) {
        goToSection(sectionIndex);
      }
    }
  }, [section, goToSection]);

  const handleNavigationWithCheck = useCallback(
    (type: "section" | "back" | "next" | "previous", index?: number) => {
      if (isFormDirty) {
        setTargetNavigation({ type, index });
        setShowUnsavedModal(true);
      } else {
        executeNavigation(type, index);
      }
    },
    [isFormDirty]
  );

  const executeNavigation = (
    type: "section" | "back" | "next" | "previous",
    index?: number
  ) => {
    const navUrl = `/tasks/${instanceToEdit?.parentTask._id}_${instanceToEdit?.date}`;
    switch (type) {
      case "section":
        navigate(`${navUrl}/section/${index}`);
        break;
      case "back":
        navigate(`${navUrl}`);
        break;
      case "next":
        navigate(`${navUrl}/section/${currentIndex + 1}`);
        break;
      case "previous":
        navigate(`${navUrl}/section/${currentIndex - 1}`);
        break;
    }
  };

  const handleNavigateUnsaved = () => {
    if (targetNavigation) {
      setShowUnsavedModal(false);
      setIsFormDirty(false);
      executeNavigation(targetNavigation.type, targetNavigation.index);
      setTargetNavigation(null);
    }
  };

  const handleSectionClick = (index: number) => {
    handleNavigationWithCheck("section", index);
  };

  const handleMobileNavigation = (action: "next" | "previous") => {
    handleNavigationWithCheck(action);
  };

  const handleBackNavigation = () => {
    handleNavigationWithCheck("back");
  };

  if (!instanceToEdit) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col h-full bg-primary w-full">
      <PageHeader
        title={
          <button
            type="button"
            className="flex gap-2 items-center text-md font-normal text-secondary-300 hover:text-secondary-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-100"
            onClick={handleBackNavigation}
          >
            <ArrowBack style={{ fontSize: "1rem" }} />
            Back to {instanceToEdit?.parentTask.title}
          </button>
        }
        mobileTitle={
          <>
            <span className="inline-flex gap-2">Back to {instanceToEdit?.parentTask.title}</span>
          </>
        }
      />
      <TaskInstanceForm
        key={section}
        currentIndex={section ? parseInt(section) : currentIndex}
        goToSection={handleSectionClick}
        goToNextSection={() => handleMobileNavigation("next")}
        goToPreviousSection={() => handleMobileNavigation("previous")}
        isFirstSection={section ? parseInt(section) === 0 : isFirstSection}
        isLastSection={
          section
            ? instanceToEdit?.parentTask?.map?.fields
              ? parseInt(section) ===
                instanceToEdit.parentTask.map.fields.length - 1
              : false
            : isLastSection
        }
        onDirtyStateChange={setIsFormDirty}
      />
      {showUnsavedModal && (
        <GenericModal
          title="Unsaved Changes"
          buttonText="Leave"
          handleFunction={handleNavigateUnsaved}
          item={instanceToEdit}
          showModal={showUnsavedModal}
          setShowModal={setShowUnsavedModal}
        />
      )}
    </div>
  );
};

export default TaskInstanceFormPage;
