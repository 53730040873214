import { memo, useEffect } from "react";
import {
  UseFormRegister,
  Control,
  FieldErrors,
  UseFormClearErrors,
  useFormContext,
} from "react-hook-form";
import Input, { InputType } from "../general-ui/Input";
import { NewRecurringTaskFormData } from "./RecurringTaskForm";

interface FormFieldProps {
  input: {
    id: string;
    type: InputType;
    label: string;
    options?: { value: string; label: string }[];
  };
  register: UseFormRegister<NewRecurringTaskFormData>;
  control: Control<NewRecurringTaskFormData>;
  errors: FieldErrors<NewRecurringTaskFormData>;
  clearErrors: UseFormClearErrors<NewRecurringTaskFormData>;
}

const FormField = memo(
  ({ input, register, control, errors, clearErrors }: FormFieldProps) => {
    const { watch, trigger } = useFormContext<NewRecurringTaskFormData>();

    const fieldValue = watch(input.id as keyof NewRecurringTaskFormData);

    const getNestedError = (fieldName: string) => {
      if (fieldName.includes(".")) {
        const [parent, child] = fieldName.split(".");
        return (errors[parent as keyof NewRecurringTaskFormData] as any)?.[child];
      }
      return errors[fieldName as keyof NewRecurringTaskFormData];
    };

    useEffect(() => {
      if (fieldValue) {
        clearErrors(input.id as keyof NewRecurringTaskFormData);
      }
    }, [fieldValue, input.id, clearErrors]);

    return (
      <div className="py-2 sm:grid sm:grid-cols-4">
        <label
          htmlFor={input.id}
          className="flex items-start text-sm font-medium leading-6 mb-2"

        >
          {input.label}
        </label>
        <div className="sm:col-span-3">
          <Input
            key={input.id}
            label={input.label}
            name={input.id}
            type={input.type}
            options={input.options}
            register={register}
            control={control}
            errors={getNestedError(input.id)}
            showLabel={false}
            onBlur={() => {
              trigger(input.id as keyof NewRecurringTaskFormData);
            }}
          />
        </div>
      </div>
    );
  }
);

FormField.displayName = "FormField";

export default FormField; 