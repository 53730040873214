import { Fragment, useState } from "react";
import { Combobox as HeadlessCombobox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { Control, Controller } from "react-hook-form";

interface Option {
  value: string;
  label: string;
}

interface ComboboxProps {
  options: Option[];
  name: string;
  control: Control<any>;
  placeholder?: string;
  label?: string;
  showLabel?: boolean;
  disabled?: boolean;
  isOptionDisabled?: (option: Option) => boolean;
}

const Combobox: React.FC<ComboboxProps> = ({
  options,
  name,
  control,
  placeholder = "Select an option",
  label,
  showLabel = true,
  disabled = false,
  isOptionDisabled,
}) => {
  const [query, setQuery] = useState("");

  const filteredOptions =
    query === ""
      ? options
      : options.filter((option) =>
          option.label
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <div className="w-full">
          {showLabel && label && (
            <label className="block text-sm font-medium leading-6 mb-2">
              {label}
            </label>
          )}
          <HeadlessCombobox
            value={options.find((option) => option.value === value) || null}
            onChange={(option: Option | null) => {
              if (option && isOptionDisabled?.(option)) return;
              onChange(option?.value || "");
            }}
            disabled={disabled}
          >
            <div className="relative">
              <div
                className={`relative w-full cursor-default overflow-hidden rounded-md bg-transparent text-left shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-accent-500 sm:text-sm sm:leading-6 ${
                  error
                    ? "ring-reds-600 ring-2 text-reds-600"
                    : "ring-secondary-800 text-secondary-100"
                }`}
              >
                <HeadlessCombobox.Input
                  className="w-full border-none py-1.5 pl-3 pr-10 text-sm leading-5 bg-transparent focus:ring-0"
                  onChange={(event) => setQuery(event.target.value)}
                  displayValue={(option: Option) => option?.label || ""}
                  placeholder={placeholder}
                />
                <HeadlessCombobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-secondary-400"
                    aria-hidden="true"
                  />
                </HeadlessCombobox.Button>
              </div>
              <HeadlessCombobox.Options className="absolute z-50 mt-1 max-h-48 w-full overflow-auto rounded-md bg-primary py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {filteredOptions.length === 0 && query !== "" ? (
                  <div className="relative cursor-default select-none px-4 py-2 text-secondary-400">
                    Nothing found.
                  </div>
                ) : (
                  filteredOptions.map((option) => {
                    const isDisabled = isOptionDisabled?.(option);
                    return (
                      <HeadlessCombobox.Option
                        key={option.value}
                        value={option}
                        as={Fragment}
                        disabled={isDisabled}
                      >
                        {({ active, selected }) => (
                          <li
                            className={`relative border-b border-secondary-1000 cursor-default select-none py-2 pl-10 pr-4 ${
                              isDisabled
                                ? "opacity-50 cursor-not-allowed text-secondary-400"
                                : active
                                ? "bg-secondary-100 text-white"
                                : "text-secondary-100"
                            }`}
                          >
                            <span
                              className={`block truncate ${
                                selected ? "font-medium" : "font-normal"
                              }`}
                            >
                              {option.label}
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-accent-500">
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </li>
                        )}
                      </HeadlessCombobox.Option>
                    );
                  })
                )}
              </HeadlessCombobox.Options>
            </div>
          </HeadlessCombobox>
          {error && (
            <p className="mt-1 text-sm text-reds-500">{error.message}</p>
          )}
        </div>
      )}
    />
  );
};

export default Combobox;
