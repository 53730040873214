import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import { useNavigate } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useTaskScheduler } from "../../hooks/useTaskScheduler";
import RecurringTaskPreview from "../../updatedcomponents/data-display/RecurringTaskPreview";
import RecurringTaskForm from "../../updatedcomponents/forms/RecurringTaskForm";
const TaskSchedulerInstance = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const {
    selectedTask,
    showModalState,
    updateShowModalState,
    isEditing,
    setIsEditing,
  } = useTaskScheduler();

  

  return (
    <div className="flex flex-col h-full overflow-hidden relative">
      <PageHeader
        title="Schedule Task"
        mobileTitle={
          <button
            type="button"
            className="flex gap-2 items-center text-md font-normal text-secondary-300 hover:text-secondary-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-100"
            onClick={handleBack}
          >
            <ChevronLeftIcon className="text-inherit h-4 w-4" />
          </button>
        }
      />
      <div className="flex flex-1 sm:hidden flex-col flex-grow h-full overflow-hidden w-full bg-primary">
        {isEditing ? (
          <RecurringTaskForm
          />
        ) : (
          <RecurringTaskPreview
            taskData={selectedTask}
            onEdit={() => setIsEditing(true)}
            onDelete={() => updateShowModalState("deleteTask", true)}
          />
        )}
      </div>
    </div>
  );
};

export default TaskSchedulerInstance;
