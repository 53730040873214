import React from "react";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import RecurringTaskForm from "../../updatedcomponents/forms/RecurringTaskForm";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";

const CreateTask: React.FC = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="flex flex-col h-full overflow-hidden relative">
      <PageHeader
        title="Schedule Task"
        mobileTitle={
          <>
            <span className="inline-flex gap-2">Schedule a Recurring Task</span>
          </>
        }
        subtitle="Get started by filling in the information below to schedule a new recurring task"
      />
      <div className="flex flex-1 sm:hidden flex-col flex-grow h-full overflow-hidden w-full bg-primary  p-4">
        <RecurringTaskForm />
      </div>
    </div>
  );
};

export default CreateTask;
