import { useState } from "react";
import WorkOrdersTable from "../../updatedcomponents/tables/WorkOrdersTable";
import NewWorkOrder from "../../updatedcomponents/forms/WorkOrderForm";
import WorkOrderListPrint from "../../updatedcomponents/modals/WorkOrderListPrint";
import Pagination from "../../updatedcomponents/table-components/Pagination";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import FilterModal from "../../updatedcomponents/table-components/FilterModal";
import Button from "../../updatedcomponents/general/Button";
import FilterButton from "../../updatedcomponents/table-components/FilterButton";
import { useWorkOrder } from "../../hooks/useWorkOrder";
import { Kiosk } from "../../types/Kiosk";
import { User } from "../../types/User";
import Modal from "../../updatedcomponents/general-ui/Modal";
import { useAuth } from "../../hooks/useAuth";
import { useFacility } from "../../hooks/useFacility";
import Selector from "../../updatedcomponents/general-ui/Selector";
import {
  AdjustmentsHorizontalIcon,
  MagnifyingGlassIcon,
  PlusIcon,
  PrinterIcon,
  RectangleStackIcon,
  ShareIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import SearchInput from "../../updatedcomponents/general-ui/SearchBar";
import Drawer from "../../updatedcomponents/general-ui/Drawer";
import WorkOrderDetails from "./WorkOrderDetails";
import WorkOrderCard from "../../updatedcomponents/cards/WorkOrderCard";
import InfiniteScroll from "../../updatedcomponents/table-components/InfiniteScroll";
import SliderContainer from "../../updatedcomponents/general-ui/SliderContainer";
import { Tooltip } from "react-tooltip";
import MultiAction from "../../updatedcomponents/table-components/MultiAction";
import DateFilter from "../../updatedcomponents/general-ui/DateFilter";
import { DateParams } from "../../types/Dates";
import moment from "moment-timezone";

const WorkOrders = () => {
  const {
    totalItems,
    handleSort,
    sortColumn,
    filters,
    applyFilters,
    applyIndividualFilter,
    resetFilters,
    users,
    kiosks,
    modalShowState,
    updateModalShowState,
    handleCreateWorkOrder,
    handleShareWorkOrder,
  } = useWorkOrder();
  const { currentUser } = useAuth();
  const { selectedFacility } = useFacility();

  const filterOptions = [
    {
      name: "status",
      label: "Status",
      type: "selector",
      options: [
        { value: "", label: "All Statuses" },
        { value: "incomplete", label: "Incomplete" },
        { value: "unable", label: "Unable" },
        { value: "complete", label: "Complete" },
      ],
    },
    { name: "requestedBy", label: "Requested By", type: "text" },
    {
      name: "requestOrigin",
      label: "Request Origin",
      type: "selector",
      options: [
        { value: "", label: "All Origins" },
        ...(kiosks && kiosks.length > 0
          ? kiosks.map((kiosk: Kiosk) => ({
              value: kiosk.location,
              label: kiosk.location,
            }))
          : []),
        { value: "user-login", label: "User-login" },
      ],
    },
    {
      name: "requestType",
      label: "Request Type",
      type: "selector",
      options: [
        { value: "", label: "All Types" },
        { value: "Electrical", label: "Electrical" },
        { value: "Plumbing", label: "Plumbing" },
        { value: "HVAC", label: "HVAC" },
        { value: "Other", label: "Other" },
      ],
    },
    {
      name: "urgency",
      label: "Urgency",
      type: "selector",
      options: [
        { value: "", label: "All Urgencies" },
        { value: "standard", label: "Standard" },
        { value: "urgent", label: "Urgent" },
      ],
    },
  ];

  if (currentUser.access !== "worker") {
    filterOptions.push({
      name: "assignedTo",
      label: "Assigned To",
      type: "selector",
      options: [
        { value: "", label: "All Assignments" },
        { value: "unassigned", label: "Unassigned" },
        ...(users && users.length > 0
          ? users.map((user: User) => ({
              value: user._id,
              label: `${user.firstname} ${user.lastname}`,
            }))
          : []),
      ],
    });
  }

  const facility = currentUser?.facility.find(
    (facility) => facility._id === selectedFacility
  );

  const sortOptions = [
    { value: "", label: "None" },
    { value: "title", label: "Title" },
    { value: "dateReported", label: "Date Reported" },
    { value: "urgency", label: "Urgency" },
    { value: "requestType", label: "Request Type" },
    { value: "status", label: "Status" },
  ];
  const [isActiveSearch, setIsActiveSearch] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  return (
    <div className="flex flex-col h-full bg-secondary-1100 sm:bg-primary">
      <PageHeader
        title={
          <>
            <span className="inline-flex gap-2">{facility?.name}</span>
          </>
        }
        mobileTitle={
          <>
            <span className="inline-flex gap-2">Work Orders</span>
          </>
        }
        subtitle={
          <div className="flex gap-2 items-center">

            <span className="hidden  sm:inline-flex">
              Total amount: {totalItems}
            </span>
            <p className="sm:hidden">
              Work orders that have not been closed out yet.
            </p>
          </div>
        }
        buttons={
          <div className="flex flex-col sm:flex-row w-full h-fit justify-evenly sm:gap-4 gap-2 bg-primary">
            <SearchInput
              searchTerm={filters.q || ""}
              handleSearchChange={(searchTerm) =>
                applyIndividualFilter("q", searchTerm)
              }
            />
            <Button
              styleString="secondary"
              onClick={() => updateModalShowState("showPrintWorkOrders", true)}
              icon="PrintOutlined"
              children=""
              overrideStyles="w-full"
            />
            <Button
              styleString="primary"
              onClick={() => handleCreateWorkOrder("drawer")}
              icon={"Add"}
              children="Create Order"
              overrideStyles="w-full"
            />
          </div>
        }
        children={
          <button
            onClick={() => handleCreateWorkOrder("navigate")}
            className="sm:hidden text-primary bg-secondary p-2.5 h-fit my-auto rounded-md"
          >
            <PlusIcon className="h-5 w-5 " aria-hidden="true" />
          </button>
        }
      />
      <div className="flex lg:hidden w-full justify-between items-center bg-primary shadow border-b-2 border-secondary-1000 py-2.5 px-4 text-secondary-400 gap-2">
        <div
          className={`flex gap-4 text-sm ${
            isActiveSearch ? "hidden" : "w-fit"
          }`}
        >
          <button
            onClick={() => updateModalShowState("showFilterModal", true)}
            className="py-2 px-3 border border-secondary-1000 rounded-lg flex items-center gap-2"
          >
            <AdjustmentsHorizontalIcon className="h-4 w-4" />
            <span> Filter</span>
          </button>
          <Selector
            icon={<RectangleStackIcon className="h-5 w-5" />}
            key={"sortOrder"}
            placeholder="Group By: None"
            options={sortOptions}
            value={sortOptions.find((option) => option.value === sortColumn)}
            onChange={(selectedOption) =>
              handleSort(String(selectedOption.value))
            }
          />
        </div>

        <div
          className={`flex items-center ${
            isActiveSearch ? "w-full" : "w-fit"
          } px-3 border border-secondary-1000 rounded-lg justify-end h-full`}
        >
          <div
            className={`${
              isActiveSearch
                ? "opacity-100 w-full flex justify-between items-center"
                : "opacity-0 w-0 overflow-hidden"
            } flex transition-all duration-200 ease-in-out`}
          >
            <input
              type="text"
              className={`flex flex-row-reverse py-1.5 px-2 border-none focus:outline-none focus:ring-transparent  transition-opacity duration-500 ease-in-out
                ${
                  isActiveSearch
                    ? "opacity-100 w-full items-center"
                    : "opacity-0 w-0"
                }
                `}
              value={filters.q || ""}
              onChange={(e) => applyIndividualFilter("q", e.target.value)}
              placeholder="Search..."
            />
            <button
              className={`transition-opacity duration-200 ease-in-out ${
                isActiveSearch
                  ? "opacity-100 w-fit items-center"
                  : "opacity-0 w-0"
              }`}
              onClick={() => {
                setIsActiveSearch(false);
                applyIndividualFilter("q", "");
              }}
            >
              <span className="sr-only">Clear search</span>
              <XMarkIcon className="h-5 w-5 text-secondary-400" />
            </button>
          </div>

          <button
            onClick={() => setIsActiveSearch(!isActiveSearch)}
            className={` ${isActiveSearch ? "opacity-0 w-0" : "w-5"}`}
          >
            <MagnifyingGlassIcon className="h-5 w-5" />
          </button>
        </div>
      </div>
      <div className="hidden lg:flex border-b border-secondary-1000 w-full justify-between items-center px-6 py-3">
        <div className="flex gap-2 ">
          {filterOptions
            .filter((option) => option.type === "selector")
            .map((filterOption) => (
              <Selector
                className="flex-1 flex "
                key={filterOption.name}
                placeholder={filterOption.label}
                options={filterOption.options || []}
                value={
                  filters[filterOption.name as keyof typeof filters] !==
                  undefined
                    ? filterOption.options?.find(
                        (option) =>
                          option.value ===
                          filters[filterOption.name as keyof typeof filters]
                      )
                    : filterOption.options?.[0] // Provide a default value if undefined
                }
                onChange={(selectedOption) =>
                  applyIndividualFilter(
                    filterOption.name,
                    String(selectedOption.value)
                  )
                }
              />
            ))}

        </div>
        <div className="w-fit">
          <Button
            styleString="secondary"
            children="Reset Filters"
            icon="Refresh"
            onClick={resetFilters}
          />
        </div>
      </div>
      <WorkOrdersTable />
      <MultiAction />
      <Drawer
        open={modalShowState.showNewWorkOrder}
        setOpen={() => updateModalShowState("showNewWorkOrder", false)}
        title={<>New Work Order</>}
        content={
          <NewWorkOrder
            setShowPopup={() => updateModalShowState("showNewWorkOrder", false)}
          />
        }
      />
      <SliderContainer
        show={modalShowState.showPrintWorkOrders}
        setShow={() => updateModalShowState("showPrintWorkOrders", false)}
        title={
          <div className="flex items-center gap-2 pt-10">
            <PrinterIcon className="h-5 w-5" />{" "}
            <span className="text-xl font-medium">Print Work Orders</span>
          </div>
        }
        description={"Select who you would like to print for"}
      >
        <WorkOrderListPrint
          showPrintWorkOrders={modalShowState.showPrintWorkOrders}
          setShowWorkOrderPrint={() =>
            updateModalShowState("showPrintWorkOrders", false)
          }
        />
      </SliderContainer>
      <Drawer
        open={modalShowState.showWorkOrder}
        setOpen={() => updateModalShowState("showWorkOrder", false)}
        title={
          <>
            <button
              onClick={() => {
                handleShareWorkOrder();
                setIsTooltipOpen(true);
                setTimeout(() => setIsTooltipOpen(false), 3000);
              }}
              data-tooltip-id="share-tooltip"
              data-tooltip-content="Work order copied to clipboard"
            >
              <ShareIcon
                className="h-6 w-6 text-secondary-300"
                aria-hidden="true"
              />
            </button>
            <Tooltip
              id="share-tooltip"
              place="top"
              isOpen={isTooltipOpen}
              openOnClick={false}
            />
          </>
        }
        content={<WorkOrderDetails />}
      />

      <FilterModal
        show={modalShowState.showFilterModal}
        setShow={() =>
          updateModalShowState(
            "showFilterModal",
            !modalShowState.showFilterModal
          )
        }
        filterOptions={filterOptions}
        filters={filters}
        applyFilters={applyFilters}
        resetFilters={resetFilters}
      />
    </div>
  );
};

export default WorkOrders;
