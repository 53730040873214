import React from "react";
import { capitalizeString } from "../../utils/FormatFunctions";
import { BoltIcon } from "@heroicons/react/24/outline";

interface StatusBadgeProps {
  status: string;
  icon?: React.ReactNode;
}

const StatusBadge: React.FC<StatusBadgeProps> = ({ status }) => {
  switch (status) {
    case "not started":
    case "incomplete":
      return (
        <span className="inline-flex items-center justify-center rounded-md bg-secondary-1100 px-2 py-1 text-xs text-secondary-100 ">
          {capitalizeString(status)}
        </span>
      );
      case "unable":
      return (
        <span className="inline-flex items-center justify-center rounded-md bg-secondary-300 px-2 py-1 text-xs text-primary ">
          {capitalizeString(status)}
        </span>
      );
    case "failed":
    case "overdue":
      return (
        <span className="inline-flex items-center justify-center rounded-md bg-reds-500 px-2 py-1 text-xs text-primary ring-1 ring-inset ring-reds-400/10">
          {capitalizeString(status)}
        </span>
      );
    case "standard":
      return (
        <span className="inline-flex items-center justify-center rounded-md bg-slate-400 px-2 py-1 text-xs text-primary ring-1 ring-inset ring-slate-400/10">
          {capitalizeString(status)}
        </span>
      );
    case "urgent":
      return (
        <span className="inline-flex items-center  rounded-md bg-reds-500 px-2 py-1 text-xs  text-primary ring-1 ring-inset ring-reds-400/10">
          <BoltIcon className="h-3 w-3 mr-2" />
          {capitalizeString(status)}
        </span>
      );
    case "in progress":
      return (
        <span className="inline-flex items-center rounded-md bg-secondary-1200 px-2 py-1 text-xs text-secondary-400 ring-1 ring-inset ring-secondary-400/10">
          <span className="bg-secondary-400 h-1 w-1 rounded-full mr-2" />{" "}
          {capitalizeString(status)}
        </span>
      );
    case "complete":
      return (
        <span className="inline-flex items-center justify-center  rounded-md bg-accent-500 px-2 py-1 text-xs text-primary ">
          {capitalizeString(status)}
        </span>
      );
    case "closed":
      return (
        <span className="inline-flex items-center justify-center rounded-md bg-accent-200 px-2 py-1 text-xs text-primary ring-1 ring-inset ring-accent-200/20">
          {capitalizeString(status)}
        </span>
      );
    default:
      return <span>{capitalizeString(status)}</span>;
  }
};

export default StatusBadge;
